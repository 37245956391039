import { BaseContext } from "../../../contexts/BaseContext";
import { useContext, useState } from "react";
import TagsTable from "./components/TagsTable";
import TagEditor from "./components/TagEditor/TagEditor";

const SavedTags = () => {
  const { deasyApiKey, setDeasyApiKey, savedTags } = useContext(BaseContext);
  const [apiKeyInput, setApiKeyInput] = useState("");

  const handleSaveApiKey = async (e) => {
    e.preventDefault();
    setDeasyApiKey(apiKeyInput);
  };

  if (!deasyApiKey || savedTags === null) {
    return (
      <div className="flex flex-col items-center justify-center gap-4 p-8">
        <svg
          className="w-12 h-12 text-gray-400"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M15 7a2 2 0 012 2m4 0a6 6 0 01-7.743 5.743L11 17H9v2H7v2H4a1 1 0 01-1-1v-2.586a1 1 0 01.293-.707l5.964-5.964A6 6 0 1121 9z"
          />
        </svg>
        <h2 className="text-xl font-semibold text-gray-600">
          API Key Required
        </h2>
        <p className="text-sm text-gray-500 text-center">
          Please configure your Deasy API key to continue
        </p>
        <form
          onSubmit={async (e) => handleSaveApiKey(e)}
          className="w-full max-w-md space-y-4"
        >
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Deasy API Key
            </label>
            <input
              type="password"
              value={apiKeyInput}
              onChange={(e) => setApiKeyInput(e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:border-emerald-500"
              placeholder="Enter your Deasy API key"
              required
            />
          </div>
          <button
            type="submit"
            className="w-full btn btn-primary bg-emerald-600 hover:bg-emerald-700 text-white py-2 px-4 rounded-md"
          >
            Save API Key
          </button>
        </form>
      </div>
    );
  }

  return (
    <div className="flex flex-row gap-4 h-full p-4">
      <div className="flex-grow">
        <TagsTable />
      </div>
      <div className="h-full">
        <TagEditor />
      </div>
    </div>
  );
};

export default SavedTags;
