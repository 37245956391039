import { useContext } from "react";
import { BaseContext } from "./contexts/BaseContext";
import NavBar from "./components/NavBar/NavBar";
import TokenDashboard from "./components/MainContent/Token/TokenDashboard";
import VectorDB from "./components/MainContent/VectorDB/VectorDB";
import Metadata from "./components/MainContent/MetadataDashboard/Metadata";
import AutoCreate from "./components/MainContent/AutoCreate/AutoCreate";
import TaggingStudio from "./components/MainContent/TaggingStudio/TaggingStudio";
import SavedTags from "./components/MainContent/SavedTags/SavedTags";
import DefaultPage from "./components/MainContent/Utils/DefaultPage";
import MissingVDB from "./components/MainContent/Utils/MissingVDB";

// Helper functions
const isVDBConfigured = (config) => {
  return (
    config.type !== "" &&
    config.apiKey !== "" &&
    config.collection !== "" &&
    (config.url !== "" || config.type === "pinecone")
  );
};

const pageTitles = {
  tokenDashboard: "Deasy API keys and credits",
  vectorDatabase: "Vector Database Configuration",
  metadataDashboard: "Metadata Dashboard",
  autoCreateTags: "Automatic Tag Creation",
  taggingStudio: "Tagging Studio",
  savedTags: "Deasy Metadata Schemas",
  "": "Welcome to DeasyLabs",
};

const Home = () => {
  const { activePage, vectorDBConfiguration, exportData, savedTags } =
    useContext(BaseContext);

  const vdbConfigured = isVDBConfigured(vectorDBConfiguration);

  const handleDownloadPackage = () => {
    const a = document.createElement("a");
    a.href = "/deasy-client.zip";
    a.download = "deasy-client.zip";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const renderContent = () => {
    if (
      ["metadataDashboard", "taggingStudio", "autoCreateTags"].includes(
        activePage,
      ) &&
      !vdbConfigured
    ) {
      return (
        <MissingVDB helpMessage="Please configure your vector database to view metadata analytics" />
      );
    }

    const contentMap = {
      vectorDatabase: <VectorDB />,
      metadataDashboard: vdbConfigured && <Metadata />,
      taggingStudio: vdbConfigured && <TaggingStudio />,
      autoCreateTags: vdbConfigured && <AutoCreate />,
      savedTags: <SavedTags />,
      tokenDashboard: <TokenDashboard />,
      "": <DefaultPage />,
    };

    return contentMap[activePage] || null;
  };

  return (
    <div className="flex h-screen overflow-hidden bg-gradient-to-br from-teal-200 via-emerald-400 to-teal-600">
      <NavBar />

      {/* Main Content Area */}
      <div className="flex-1 flex flex-col min-h-0">
        {/* Header with Export Buttons */}
        <div className="p-4 pt-3 pb-0">
          <div className="flex justify-between items-center">
            <h2 className="text-3xl font-semibold text-gray-800 ml-5 mt-4">
              {pageTitles[activePage]}
            </h2>
            <div className="flex space-x-2">
              <button
                className="px-4 py-2 bg-white text-teal-600 rounded-lg shadow-md hover:shadow-lg transition-all duration-200 flex items-center space-x-2 font-medium"
                onClick={handleDownloadPackage}
                title="Download Client Package"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M4 4a2 2 0 012-2h4.586A2 2 0 0112 2.586L15.414 6A2 2 0 0116 7.414V16a2 2 0 01-2 2H6a2 2 0 01-2-2V4zm2 6a1 1 0 011-1h6a1 1 0 110 2H7a1 1 0 01-1-1zm1 3a1 1 0 100 2h6a1 1 0 100-2H7z"
                    clipRule="evenodd"
                  />
                </svg>
                <span>Download Package</span>
              </button>
              <button
                className={`px-4 py-2 bg-white text-teal-600 rounded-lg shadow-md hover:shadow-lg transition-all duration-200 flex items-center space-x-2 font-medium ${
                  !savedTags?.length ? "opacity-50 cursor-not-allowed" : ""
                }`}
                onClick={() => exportData({ type: "json" })}
                disabled={!savedTags?.length}
                title={
                  !savedTags?.length
                    ? "Please create at least one tag before exporting"
                    : "Export Tags to JSON"
                }
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm3.293-7.707a1 1 0 011.414 0L9 10.586V3a1 1 0 112 0v7.586l1.293-1.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
                <span>Export Tags</span>
              </button>
            </div>
          </div>
        </div>

        {/* Content Area with Scroll */}
        <div className="flex-1 overflow-auto p-4">{renderContent()}</div>
      </div>
    </div>
  );
};

export default Home;
