import { useState, useEffect, useContext } from "react";
import { metadataService } from "../../../services/api";
import { BaseContext } from "../../../contexts/BaseContext";
import { prepareVectorDBConfiguration } from "../../../services/utils";

const FileList = ({
  selectedFiles,
  setSelectedFiles,
  setError,
  showTitle = true,
}) => {
  const {
    deasyApiKey,
    vectorDBConfiguration,
    availableFiles,
    setAvailableFiles,
  } = useContext(BaseContext);
  const [searchTerm, setSearchTerm] = useState("");
  const [loadingFiles, setLoadingFiles] = useState(availableFiles.length === 0);

  const filteredFiles = availableFiles.filter((file) =>
    file.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  const handleFileSelect = (file) => {
    setSelectedFiles((prev) =>
      prev.includes(file) ? prev.filter((f) => f !== file) : [...prev, file],
    );
  };

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    // Skip if we don't have the required configuration
    if (!deasyApiKey || !vectorDBConfiguration) {
      return;
    }

    const fetchFiles = async () => {
      try {
        setLoadingFiles(true);
        const response = await metadataService.listMetadata(
          prepareVectorDBConfiguration(vectorDBConfiguration),
          deasyApiKey,
        );
        setAvailableFiles(Object.keys(response.data.metadata) || []);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoadingFiles(false);
      }
    };

    if (availableFiles.length === 0) {
      fetchFiles();
    }
  }, [deasyApiKey]); // Only depend on the API key
  /* eslint-disable react-hooks/exhaustive-deps */

  return (
    <div className="bg-white rounded-lg p-4">
      {/* Header with title and file count */}
      <div className="flex justify-between items-center mb-4">
        {showTitle && <h3 className="text-lg font-medium">1. Select Files</h3>}
        <span className="text-sm text-gray-600">
          Selected: {selectedFiles.length} files
        </span>
      </div>

      {/* Search Bar */}
      <div className="mb-4">
        <input
          type="text"
          placeholder="Search files..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
      </div>

      {/* Quick Actions */}
      <div className="flex justify-between items-center mb-2">
        <div className="flex gap-2">
          <button
            onClick={() => setSelectedFiles(filteredFiles)}
            className="btn btn-sm btn-outline"
            disabled={filteredFiles.length === 0}
          >
            Select All {searchTerm ? "Filtered" : ""}
          </button>
          <button
            onClick={() => setSelectedFiles([])}
            className="btn btn-sm btn-outline"
            disabled={selectedFiles.length === 0}
          >
            Clear All
          </button>
        </div>
        {searchTerm && (
          <span className="text-sm text-gray-500">
            Showing {filteredFiles.length} of {availableFiles.length} files
          </span>
        )}
      </div>

      {/* File List with Loading State */}
      <div className="max-h-[400px] overflow-y-auto border rounded-lg bg-white">
        {loadingFiles ? (
          <div className="flex items-center justify-center h-32">
            <span className="loading loading-spinner loading-md"></span>
            <span className="ml-2 text-gray-600">Loading files...</span>
          </div>
        ) : availableFiles.length === 0 ? (
          <div className="flex items-center justify-center h-32 text-gray-500">
            No files available
          </div>
        ) : (
          <div className="divide-y divide-gray-100">
            {filteredFiles.map((file) => (
              <div
                key={file}
                onClick={() => handleFileSelect(file)}
                className={`px-4 py-3 cursor-pointer hover:opacity-80 transition-colors flex justify-between items-center
                ${selectedFiles.includes(file) ? "bg-gray-200" : ""}`}
              >
                <span className="truncate text-sm text-gray-700">{file}</span>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default FileList;
