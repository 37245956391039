import axios from "axios";
import { auth } from "../config/firebase";

const API_URL = process.env.REACT_APP_API_URL;

// Create single API instance
const api = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

// Add authentication interceptor for console endpoints
const getAuthHeaders = async () => {
  const user = auth.currentUser;
  if (user) {
    const token = await user.getIdToken();
    return {
      Authorization: `Bearer ${token}`,
      "x-user": user.email,
    };
  }
  return {};
};

// Metadata specific headers
export const tokenService = {
  listTokens: async () =>
    api.get("/console/token/list", { headers: await getAuthHeaders() }),
  createToken: async (username) =>
    api.post(
      "/console/token/create",
      { username },
      { headers: await getAuthHeaders() },
    ),
  deleteToken: async (tokenId, softDelete = true) =>
    api.delete(`/console/token/${tokenId}`, {
      data: { soft_delete: softDelete },
      headers: await getAuthHeaders(),
    }),
  getSecret: async (secretName) =>
    api.post(
      `/console/secret/get`,
      { secret_name: secretName },
      { headers: await getAuthHeaders() },
    ),
  storeSecret: async (secretName, secretValue) =>
    api.post(
      `/console/secret/store`,
      { secret_name: secretName, secret_value: secretValue },
      { headers: await getAuthHeaders() },
    ),
  createPaymentSession: async (credits) =>
    api.post(
      "/console/payment/create-session",
      { credits },
      { headers: await getAuthHeaders() },
    ),
  verifyPayment: async (sessionId) =>
    api.post(
      "/console/payment/verify",
      { session_id: sessionId },
      { headers: await getAuthHeaders() },
    ),
};

export const metadataService = {
  getMetadataHealth: async (vectorDBConfiguration, deasyApiKey) =>
    api.post(
      "/metadata_health",
      { vector_db_config: vectorDBConfiguration },
      { headers: { "x-token": deasyApiKey, "x-user": auth.currentUser.email } },
    ),
  createTag: async (tagData, deasyApiKey) =>
    api.post(
      "/tags/create",
      { tag_data: tagData },
      {
        headers: { "x-token": deasyApiKey, "x-user": auth.currentUser.email },
      },
    ),
  getSavedTags: async (deasyApiKey) =>
    api.get("/tags/list", {
      headers: { "x-token": deasyApiKey, "x-user": auth.currentUser.email },
    }),
  updateTag: async (tagData, deasyApiKey) =>
    api.put(
      "/tags/update",
      { tag_data: tagData },
      {
        headers: { "x-token": deasyApiKey, "x-user": auth.currentUser.email },
      },
    ),
  deleteTag: async (tagId, deasyApiKey) =>
    api.delete(`/tags/delete/${tagId}`, {
      headers: { "x-token": deasyApiKey, "x-user": auth.currentUser.email },
    }),
  autoCreateTags: async (data, deasyApiKey) =>
    api.post("/auto_create_tags", data, {
      headers: { "x-token": deasyApiKey, "x-user": auth.currentUser.email },
    }),
  listMetadata: async (vectorDBConfiguration, deasyApiKey, groupBy = "file") =>
    api.post(
      "/metadata/list",
      {
        vector_db_config: vectorDBConfiguration,
        group_by: groupBy,
      },
      { headers: { "x-token": deasyApiKey, "x-user": auth.currentUser.email } },
    ),
  taggingStudioClassify: async (
    vectorDBConfiguration,
    endpointManagerConfig,
    fileNames,
    tags,
    deasyApiKey,
  ) =>
    api.post(
      "/classify",
      {
        vector_db_config: vectorDBConfiguration,
        endpoint_manager_config: endpointManagerConfig,
        file_names: fileNames,
        tags: tags,
        soft_run: true,
        job_id: null,
        overwrite: true,
      },
      {
        headers: { "x-token": deasyApiKey, "x-user": auth.currentUser.email },
      },
    ),
};
