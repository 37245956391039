import React from "react";
import { motion } from "framer-motion";

const LoadingScreen = ({ progress, message }) => {
  return (
    <div className="fixed inset-0 bg-gradient-to-br from-teal-200 via-emerald-400 to-teal-600 flex items-center justify-center">
      <div className="bg-white rounded-2xl p-8 shadow-xl w-96 text-center">
        <motion.div
          initial={{ scale: 0.8, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          {/* Logo or Icon */}
          <div className="w-24 h-24 mx-auto mb-6">
            <img
              src="/deasy-icon.png"
              alt="DeasyLabs"
              class="w-full h-full rounded-lg brightness-125"
            />
          </div>

          {/* Loading Message */}
          <h2 className="text-2xl font-semibold text-gray-800 mb-4">
            Loading DeasyLabs
          </h2>
          <p className="text-gray-600 mb-6">{message}</p>

          {/* Progress Bar */}
          <div className="w-full bg-gray-200 rounded-full h-2 mb-4">
            <motion.div
              className="bg-primary h-full rounded-full"
              initial={{ width: 0 }}
              animate={{ width: `${progress}%` }}
              transition={{ duration: 0.5 }}
            />
          </div>

          {/* Loading Animation */}
          <div className="flex justify-center gap-1">
            <motion.span
              className="w-2 h-2 rounded-full bg-primary"
              animate={{ y: [-5, 0, -5] }}
              transition={{ duration: 1, repeat: Infinity, delay: 0 }}
            />
            <motion.span
              className="w-2 h-2 rounded-full bg-primary"
              animate={{ y: [-5, 0, -5] }}
              transition={{ duration: 1, repeat: Infinity, delay: 0.2 }}
            />
            <motion.span
              className="w-2 h-2 rounded-full bg-primary"
              animate={{ y: [-5, 0, -5] }}
              transition={{ duration: 1, repeat: Infinity, delay: 0.4 }}
            />
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default LoadingScreen;
