import { BaseContext } from "../../../contexts/BaseContext";
import { useContext } from "react";

const MissingVDB = ({ helpMessage }) => {
  const { setActivePage } = useContext(BaseContext);
  return (
    <div className="flex flex-col items-center justify-center gap-4 p-8">
      <svg
        className="w-12 h-12 text-gray-400"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M4 7v10c0 2.21 3.582 4 8 4s8-1.79 8-4V7M4 7c0 2.21 3.582 4 8 4s8-1.79 8-4M4 7c0-2.21 3.582-4 8-4s8 1.79 8 4m0 5c0 2.21-3.582 4-8 4s-8-1.79-8-4"
        />
      </svg>
      <h2 className="text-xl font-semibold text-gray-600">
        Vector Database Not Configured
      </h2>
      <p className="text-sm text-gray-500 text-center">{helpMessage}</p>
      <button
        onClick={() => setActivePage("vectorDatabase")}
        className="btn btn-primary"
      >
        Configure Vector Database
      </button>
    </div>
  );
};

export default MissingVDB;
