import React, { useState, useContext, useEffect } from "react";
import { BaseContext } from "../../../contexts/BaseContext";
import { useSavedTags } from "../../../hooks/SavedTagsHook";
import { tokenService } from "../../../services/api";

const VectorDB = () => {
  useSavedTags();

  const {
    vectorDBConfiguration,
    setVectorDBConfiguration,
    llmEndpointConfiguration,
    setLlmEndpointConfiguration,
    deasyApiKey,
    setDeasyApiKey,
    savedTags,
  } = useContext(BaseContext);

  const dbOptions = [
    { value: "QdrantVectorDBManager", label: "Qdrant" },
    { value: "ChromaVectorDBManager", label: "Chroma" },
    { value: "PineconeVectorDBManager", label: "Pinecone" },
  ];
  const llmOptions = [{ value: "openai", label: "OpenAI" }];
  const [formState, setFormState] = useState({
    deasyApiKey: deasyApiKey || "",
    type: vectorDBConfiguration.type || "",
    collection: vectorDBConfiguration.collection || "",
    apiKey: vectorDBConfiguration.apiKey || "",
    url: vectorDBConfiguration.url || "",
    llmType: llmEndpointConfiguration.llmType || "",
    llmApiKey: llmEndpointConfiguration.llmApiKey || "",
  });
  const handleInputChange = (field, value) => {
    setFormState((prev) => ({ ...prev, [field]: value }));
  };

  const updateTokenServiceSecret = (secretName, secretValue) => {
    try {
      tokenService.storeSecret(secretName, secretValue);
    } catch (err) {
      console.error("Error updating token service secret:", err);
    }
  };

  const [deasyApiKeyConfigured, setDeasyApiKeyConfigured] = useState(
    formState.deasyApiKey,
  );
  const handleSaveDeasyApiKey = (e) => {
    e.preventDefault();
    if (deasyApiKeyConfigured) {
      setDeasyApiKeyConfigured(false);
    } else if (formState.deasyApiKey) {
      setDeasyApiKey(formState.deasyApiKey);
      setDeasyApiKeyConfigured(true);
      updateTokenServiceSecret("deasyApiKey", formState.deasyApiKey);
    }
  };

  const [vdbConfigured, setVDBConfigured] = useState(
    formState.type &&
      formState.apiKey &&
      formState.collection &&
      (formState.url || formState.type === "pinecone"),
  );
  const handleSaveVectorDB = (e) => {
    e.preventDefault();
    if (vdbConfigured) {
      setVDBConfigured(false);
    } else if (
      formState.type &&
      formState.apiKey &&
      formState.collection &&
      (formState.url || formState.type === "pinecone")
    ) {
      setVectorDBConfiguration({
        type: formState.type,
        apiKey: formState.apiKey,
        collection: formState.collection,
        url: formState.url,
      });
      setVDBConfigured(true);
      updateTokenServiceSecret(
        "deasyVDBMConfig",
        JSON.stringify({
          type: formState.type,
          apiKey: formState.apiKey,
          collection: formState.collection,
          url: formState.url,
        }),
      );
    }
  };

  const [endpointConfigured, setEndpointConfigured] = useState(
    formState.llmType && formState.llmApiKey,
  );

  const handleSaveEndpoint = (e) => {
    e.preventDefault();
    if (endpointConfigured) {
      setEndpointConfigured(false);
    } else if (formState.llmType && formState.llmApiKey) {
      setLlmEndpointConfiguration({
        llmType: formState.llmType,
        llmApiKey: formState.llmApiKey,
      });
      setEndpointConfigured(true);
      updateTokenServiceSecret(
        "deasyEndpointManagerConfig",
        JSON.stringify({
          llmType: formState.llmType,
          llmApiKey: formState.llmApiKey,
        }),
      );
    }
  };

  // This means the the api key was saved but it is invalid
  useEffect(() => {
    if (savedTags === null && formState.deasyApiKey) {
      setFormState((prev) => ({
        ...prev,
        deasyApiKey: "",
      }));
      setDeasyApiKey("");
    }
  }, [savedTags, formState.deasyApiKey, setDeasyApiKey]);

  return (
    <div className="p-6 max-w-4xl mx-auto">
      {/* Vector DB Configuration Section */}
      <div className="bg-white rounded-lg shadow-lg p-6 mb-6">
        <h2 className="text-xl font-semibold mb-4 text-gray-700">
          Vector Database Configuration
        </h2>
        {/* Step 1: Database Selection */}
        <div className="mb-8">
          <h3 className="text-lg font-semibold mb-4 text-gray-700">
            1. Select Your Vector Database
          </h3>
          <div className="space-y-3">
            {dbOptions.map((db) => (
              <label
                key={db.value}
                className={`flex items-center space-x-3 cursor-pointer ${
                  vdbConfigured ? "opacity-50" : ""
                }`}
              >
                <input
                  type="radio"
                  name="database"
                  value={db.value}
                  checked={formState.type === db.value}
                  onChange={(e) => handleInputChange("type", e.target.value)}
                  className="form-radio h-5 w-5 text-emerald-600"
                  disabled={vdbConfigured}
                />
                <span className="text-gray-700">{db.label}</span>
              </label>
            ))}
          </div>
        </div>

        {/* Step 2: Connection Details */}
        <div className="space-y-6 transition-all duration-300">
          <h3 className="text-lg font-semibold mb-4 text-gray-700">
            2. Enter Connection Details
          </h3>

          <div className={`space-y-4 ${vdbConfigured ? "opacity-50" : ""}`}>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Collection Name
              </label>
              <input
                type="text"
                value={formState.collection}
                onChange={(e) =>
                  handleInputChange("collection", e.target.value)
                }
                className={`w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:border-emerald-500 ${
                  vdbConfigured ? "bg-gray-100" : ""
                }`}
                placeholder="Enter the name of your collection"
                required
                disabled={vdbConfigured}
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                API Key
              </label>
              <input
                type="password"
                value={formState.apiKey}
                onChange={(e) => handleInputChange("apiKey", e.target.value)}
                className={`w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:border-emerald-500 ${
                  vdbConfigured ? "bg-gray-100" : ""
                }`}
                placeholder="Enter your API key"
                required
                disabled={vdbConfigured}
              />
            </div>

            {formState.type !== "pinecone" && (
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Database URL
                </label>
                <input
                  type="text"
                  value={formState.url}
                  onChange={(e) => handleInputChange("url", e.target.value)}
                  className={`w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:border-emerald-500 ${
                    vdbConfigured ? "bg-gray-100" : ""
                  }`}
                  placeholder="Enter your database URL"
                  required
                  disabled={vdbConfigured}
                />
              </div>
            )}
          </div>

          <button
            onClick={handleSaveVectorDB}
            disabled={
              !formState.type ||
              !formState.apiKey ||
              !formState.collection ||
              (!formState.url && formState.type !== "pinecone")
            }
            className={`btn btn-primary w-full py-2 px-4 rounded-md text-white font-medium
                  ${
                    vdbConfigured
                      ? "bg-emerald-600 hover:bg-emerald-700"
                      : "bg-gray-400 cursor-not-allowed"
                  }
                  transition-colors duration-200`}
          >
            {vdbConfigured
              ? "Edit Vector Database Configuration"
              : "Save Vector Database Configuration"}
          </button>
        </div>
      </div>

      {/* Endpoint Configuration Section */}
      <div className="bg-white rounded-lg shadow-lg p-6 mb-6">
        <h2 className="text-xl font-semibold mb-4 text-gray-700">
          LLM Endpoint Configuration
        </h2>
        {/* Step 1: LLM Endpoint Selection */}
        <div className="mb-8">
          <h3 className="text-lg font-semibold mb-4 text-gray-700">
            1. Select Your LLM Endpoint
          </h3>
          <div className="space-y-3">
            {llmOptions.map((llm) => (
              <label
                key={llm.value}
                className={`flex items-center space-x-3 cursor-pointer ${
                  endpointConfigured ? "opacity-50" : ""
                }`}
              >
                <input
                  type="radio"
                  name="llm"
                  value={llm.value}
                  checked={formState.llmType === llm.value}
                  onChange={(e) => handleInputChange("llmType", e.target.value)}
                  className="form-radio h-5 w-5 text-emerald-600"
                  disabled={endpointConfigured}
                />
                <span className="text-gray-700">{llm.label}</span>
              </label>
            ))}
          </div>
        </div>

        {/* Step 2: Connection Details */}
        <div className="space-y-6 transition-all duration-300">
          <h3 className="text-lg font-semibold mb-4 text-gray-700">
            2. Enter Connection Details
          </h3>

          <div
            className={`space-y-4 ${endpointConfigured ? "opacity-50" : ""}`}
          >
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                API Key
              </label>
              <input
                type="password"
                value={formState.llmApiKey}
                onChange={(e) => handleInputChange("llmApiKey", e.target.value)}
                className={`w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:border-emerald-500 ${
                  endpointConfigured ? "bg-gray-100" : ""
                }`}
                placeholder="Enter your API key"
                required
                disabled={endpointConfigured}
              />
            </div>
          </div>

          <button
            onClick={handleSaveEndpoint}
            disabled={!formState.llmType || !formState.llmApiKey}
            className={`btn btn-primary w-full py-2 px-4 rounded-md text-white font-medium
                  ${
                    formState.llmType && formState.llmApiKey
                      ? "bg-emerald-600 hover:bg-emerald-700"
                      : "bg-gray-400 cursor-not-allowed"
                  }
                  transition-colors duration-200`}
          >
            {endpointConfigured
              ? "Edit Endpoint Configuration"
              : "Save Endpoint Configuration"}
          </button>
        </div>
      </div>

      {/* Deasy API Key Section */}
      <div className="bg-white rounded-lg shadow-lg p-6">
        <h2 className="text-xl font-semibold mb-4 text-gray-700">
          Deasy API Key Configuration
        </h2>
        <div className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Deasy API Key
            </label>
            <input
              type="password"
              value={formState.deasyApiKey}
              onChange={(e) => handleInputChange("deasyApiKey", e.target.value)}
              className={`w-full px-3 py-2 mb-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:border-emerald-500 ${
                deasyApiKeyConfigured ? "bg-gray-100" : ""
              }`}
              placeholder="Enter your Deasie API key"
              required
              disabled={deasyApiKeyConfigured}
            />
            <button
              onClick={handleSaveDeasyApiKey}
              type="button"
              className={`btn btn-primary mt-2 px-4 py-2 w-full`}
              disabled={!formState.deasyApiKey && !deasyApiKeyConfigured}
            >
              {deasyApiKeyConfigured ? "Edit API Key" : "Save Deasy API Key"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VectorDB;
