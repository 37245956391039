const LoadingSpinner = ({ message }) => {
  return (
    <div className="flex flex-col items-center justify-center h-full">
      <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-emerald-500"></div>
      <p className="mt-4 text-lg text-gray-600">{message}</p>
    </div>
  );
};

export default LoadingSpinner;
