import React, { useState, useEffect, useContext } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./components/Auth/Login";
import { TokenProvider } from "./contexts/TokenContext";
import Home from "./Home";
import PrivateRoute from "./components/Auth/PrivateRoute";
import { AuthProvider } from "./contexts/AuthContext";
import { Toaster } from "react-hot-toast";
import LoadingScreen from "./components/Common/LoadingScreen";
import { metadataService } from "./services/api";
import { prepareVectorDBConfiguration } from "./services/utils";
import { BaseContext } from "./contexts/BaseContext";
import { fetchUserSecrets } from "./services/utils";

function App() {
  const [isLoading, setIsLoading] = useState(true);
  const [loadingProgress, setLoadingProgress] = useState(0);
  const [loadingMessage, setLoadingMessage] = useState("Initializing...");
  const {
    setAvailableFiles,
    setDeasyApiKey,
    setSavedTags,
    setVectorDBConfiguration,
    setLlmEndpointConfiguration,
  } = useContext(BaseContext);

  useEffect(() => {
    const preloadData = async () => {
      try {
        // Initialize
        setLoadingProgress(10);
        setLoadingMessage("Initializing application...");
        await new Promise((resolve) => setTimeout(resolve, 500));

        // Auth check
        setLoadingProgress(30);
        setLoadingMessage("Checking authentication...");
        await new Promise((resolve) => setTimeout(resolve, 500));

        // Load configuration
        setLoadingProgress(50);
        setLoadingMessage("Loading configuration...");
        const { deasyKey, vectorDBConfig } = await fetchUserSecrets(
          setDeasyApiKey,
          setSavedTags,
          setVectorDBConfiguration,
          setLlmEndpointConfiguration,
        );
        await new Promise((resolve) => setTimeout(resolve, 500));

        // Load files if we have configuration
        setLoadingProgress(70);
        setLoadingMessage("Loading available files...");
        if (deasyKey && vectorDBConfig.apiKey && vectorDBConfig.collection) {
          try {
            const response = await metadataService.listMetadata(
              prepareVectorDBConfiguration(vectorDBConfig),
              deasyKey,
            );
            const files = Object.keys(response.data.metadata);
            console.log("files", files);
            setAvailableFiles(files);
          } catch (error) {
            console.error("Failed to load files:", error);
            setAvailableFiles([]);
          }
        }

        // Finalize
        setLoadingProgress(90);
        setLoadingMessage("Finalizing...");
        await new Promise((resolve) => setTimeout(resolve, 500));

        setLoadingProgress(100);
        setLoadingMessage("Ready!");
        await new Promise((resolve) => setTimeout(resolve, 300));

        setIsLoading(false);
      } catch (error) {
        console.error("Error during preload:", error);
        setAvailableFiles([]);
        setIsLoading(false);
      }
    };

    preloadData();
  }, [
    setAvailableFiles,
    setDeasyApiKey,
    setLlmEndpointConfiguration,
    setSavedTags,
    setVectorDBConfiguration,
  ]);

  if (isLoading) {
    return (
      <LoadingScreen progress={loadingProgress} message={loadingMessage} />
    );
  }

  return (
    <>
      <Toaster />
      <AuthProvider>
        <TokenProvider>
          <Router>
            <div className="App">
              <Routes>
                <Route path="/login" element={<Login />} />
                <Route
                  path="/"
                  element={
                    <PrivateRoute>
                      <Home />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/token"
                  element={
                    <PrivateRoute>
                      <Home initialTab="token" />
                    </PrivateRoute>
                  }
                />
              </Routes>
            </div>
          </Router>
        </TokenProvider>
      </AuthProvider>
    </>
  );
}

export default App;
