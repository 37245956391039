import { Tooltip } from "./MetadataOverview";

const MissingTagsTable = ({ nodeToMissingTags }) => {
  if (!nodeToMissingTags || Object.keys(nodeToMissingTags).length === 0) {
    return (
      <div className="h-full flex flex-col max-h-full">
        <div className="flex items-center gap-2 mb-4">
          <h3 className="text-lg font-semibold">Missing Tags by Node</h3>
          <Tooltip text="Nodes that are missing required tags for proper categorization" />
        </div>
        <div className="flex-1 flex items-center justify-center">
          <p className="text-gray-600">No missing tags found.</p>
        </div>
      </div>
    );
  }

  return (
    <div className="h-full flex flex-col max-h-full">
      <div className="flex items-center gap-2 mb-4">
        <h3 className="text-lg font-semibold">Missing Tags by Node</h3>
        <Tooltip text="Nodes that are missing required tags for proper categorization" />
      </div>

      <div className="bg-red-50 border border-red-200 rounded-md p-3 mb-4">
        <p className="text-sm text-red-700">
          Found {Object.keys(nodeToMissingTags).length} nodes with missing tags
        </p>
      </div>

      <div className="flex-1 min-h-0">
        <div className="h-full overflow-y-auto">
          <table className="w-full border-collapse">
            <thead>
              <tr className="bg-gray-50 sticky top-0 z-10">
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider border-b w-1/3">
                  Node ID
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider border-b w-2/3">
                  Missing Tags
                </th>
              </tr>
            </thead>
            <tbody className="bg-white">
              {Object.entries(nodeToMissingTags).map(([nodeId, tags]) => (
                <tr
                  key={nodeId}
                  className="hover:bg-gray-50 border-b border-gray-100 last:border-b-0"
                >
                  <td className="px-6 py-4 text-sm text-gray-900 align-top">
                    <div className="break-all">{nodeId}</div>
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-600 align-top">
                    <div className="flex flex-wrap gap-1">
                      {tags.map((tag) => (
                        <span
                          key={tag}
                          className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800"
                        >
                          {tag}
                        </span>
                      ))}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default MissingTagsTable;
