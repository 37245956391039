import { tokenService } from "../services/api";
import { metadataService } from "../services/api";
import { config } from "../config/config";

export const prepareVectorDBConfiguration = (vectorDBConfiguration) => {
  return {
    classname: vectorDBConfiguration.type,
    collection_name: vectorDBConfiguration.collection,
    url: vectorDBConfiguration.url,
    api_key: vectorDBConfiguration.apiKey,
    dimension_threshold: vectorDBConfiguration.dimensionThreshold,
  };
};

export const prepareEndpointManagerConfig = (endpointManagerConfig) => {
  return {
    model: "gpt-4o-mini",
    embedding_model: "text-embedding-3-small",
    api_key: endpointManagerConfig.llmApiKey,
    vendor: endpointManagerConfig.llmType.toLowerCase(),
    // seed: 42,
    // max_tokens: 1000,
    temperature: 0,
    // rpm: 1000,
    // tpm: 1000,
  };
};

export const prepareTagForTS = (tag) => {
  const tagName = tag.name;
  return {
    [tagName]: {
      name: tagName,
      description: tag.description,
      availableValues: tag.available_values,
      examples: tag.examples,
      option: tag.option,
      output_type: tag.output_type,
    },
  };
};

export const fetchUserSecrets = async (
  setDeasyApiKey,
  setSavedTags,
  setVectorDBConfiguration,
  setLlmEndpointConfiguration,
) => {
  let deasyApiKey = "";
  let savedTags = [];
  let vectorDBConfiguration = config.vectorDB.default;
  let llmEndpointConfiguration = config.llmEndpoint.default;

  try {
    const deasyApiKeyResult = await tokenService.getSecret("deasyApiKey");
    deasyApiKey = deasyApiKeyResult.data.secret || "";
    setDeasyApiKey(deasyApiKey);

    if (deasyApiKey) {
      const tagsResult = await metadataService.getSavedTags(deasyApiKey);
      savedTags = tagsResult.data.tags || [];
      setSavedTags(savedTags);
    }
  } catch (err) {
    setDeasyApiKey("");
    setSavedTags([]);
  }

  try {
    const vdbmConfigResult = await tokenService.getSecret("deasyVDBMConfig");
    vectorDBConfiguration =
      JSON.parse(vdbmConfigResult.data.secret) || config.vectorDB.default;
    setVectorDBConfiguration(vectorDBConfiguration);
  } catch (err) {
    setVectorDBConfiguration(config.vectorDB.default);
  }

  try {
    const endpointManagerConfig = await tokenService.getSecret(
      "deasyEndpointManagerConfig",
    );
    llmEndpointConfiguration =
      JSON.parse(endpointManagerConfig.data.secret) ||
      config.llmEndpoint.default;
    setLlmEndpointConfiguration(llmEndpointConfiguration);
  } catch (err) {
    setLlmEndpointConfiguration(config.llmEndpoint.default);
  }

  return {
    deasyApiKey,
    vectorDBConfiguration,
    llmEndpointConfiguration,
  };
};
