import { useContext, useState, useEffect } from "react";
import { BaseContext } from "../../../../../contexts/BaseContext";
import {
  FaChevronLeft,
  FaChevronRight,
  FaChevronDown,
  FaChevronUp,
} from "react-icons/fa";
import { metadataService } from "../../../../../services/api";
import GeneralSection from "./GeneralSection";
import ExamplesSection from "./ExamplesSection";

const TagEditor = ({
  collapseDirection = "horizontal",
  disableCollapse = false,
}) => {
  const {
    tagInEditor,
    deasyApiKey,
    setSavedTags,
    isTagEditorCollapsed,
    setIsTagEditorCollapsed,
    activeTagEditorSection,
    setActiveTagEditorSection,
  } = useContext(BaseContext);

  const [saving, setSaving] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
    description: "",
    output_type: "word",
    available_values: [],
    option: "aiGenerated",
  });

  useEffect(() => {
    if (tagInEditor && Object.keys(tagInEditor).length > 0) {
      const { isNewlySaved, ...cleanTag } = tagInEditor;
      setFormData({
        ...cleanTag,
        output_type: cleanTag.output_type || "word",
        available_values: cleanTag.available_values || [],
        option: cleanTag.option || "aiGenerated",
      });
    } else {
      setFormData({
        name: "",
        description: "",
        output_type: "word",
        available_values: [],
        option: "aiGenerated",
      });
    }
  }, [tagInEditor]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "available_values") {
      const valueArray = value === "" ? [] : value.split("\n");

      const isYesNo =
        valueArray.length === 2 &&
        valueArray.includes("Yes") &&
        valueArray.includes("No");

      setFormData((prev) => ({
        ...prev,
        [name]: valueArray,
        option: isYesNo
          ? "yesNo"
          : valueArray.length > 0
            ? "custom"
            : "aiGenerated",
      }));
    } else if (name === "option") {
      if (value === "yesNo") {
        setFormData((prev) => ({
          ...prev,
          option: "yesNo",
          available_values: ["Yes", "No"],
        }));
      } else {
        setFormData((prev) => ({
          ...prev,
          option: value,
          available_values: [],
        }));
      }
    } else {
      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleSave = async () => {
    if (!formData.name || !formData.description || !formData.output_type) {
      console.log("Please fill out all fields");
      return;
    }

    setSaving(true);

    try {
      const dataToSave = {
        ...formData,
        output_type: formData.output_type || "word",
        available_values: formData.available_values || [],
        option: formData.option || "aiGenerated",
      };

      if (formData.tag_id) {
        await metadataService.updateTag(dataToSave, deasyApiKey);
      } else {
        await metadataService.createTag(dataToSave, deasyApiKey);
      }

      const response = await metadataService.getSavedTags(deasyApiKey);
      setSavedTags(response.data.tags || []);
    } catch (error) {
      console.error("Error saving tag:", error);
    } finally {
      setSaving(false);
    }
  };

  const getCollapsedStyles = () => {
    if (collapseDirection === "vertical") {
      return {
        containerClasses: `bg-white rounded-lg shadow-md transition-all duration-300 w-full ${
          isTagEditorCollapsed ? "h-12" : "h-auto"
        }`,
        headerClasses: `flex justify-between items-center p-4 border-b border-gray-200 bg-gray-50 ${
          disableCollapse ? "" : "cursor-pointer"
        }`,
        labelStyles: { writingMode: "horizontal-tb" },
        labelRotation: "",
      };
    }

    return {
      containerClasses: `bg-white rounded-lg shadow-md transition-all duration-300 ${
        isTagEditorCollapsed ? "w-12" : "w-96"
      }`,
      headerClasses: `flex justify-between items-center p-4 border-b border-gray-200 bg-gray-50 rounded-t-lg ${
        disableCollapse ? "" : "cursor-pointer"
      }`,
      labelStyles: { writingMode: "vertical-rl" },
      labelRotation: "rotate-180",
    };
  };

  const { containerClasses, headerClasses, labelStyles, labelRotation } =
    getCollapsedStyles();

  return (
    <div className={containerClasses}>
      <TagEditorHeader
        isTagEditorCollapsed={isTagEditorCollapsed}
        setIsTagEditorCollapsed={setIsTagEditorCollapsed}
        collapseDirection={collapseDirection}
        labelStyles={labelStyles}
        labelRotation={labelRotation}
        headerClasses={headerClasses}
        disableCollapse={disableCollapse}
      />

      <div className="h-full flex flex-col">
        <div className="flex-1 overflow-y-auto p-4">
          <div className="flex bg-white rounded-t-lg border-b border-gray-200 w-[100%]">
            <button
              className={`flex-1 py-2 px-4 text-sm font-medium text-center hover:opacity-80 focus:outline-none  ${
                activeTagEditorSection === "general"
                  ? "border-b-2 border-primary text-primary"
                  : "border-transparent text-gray-500"
              }`}
              onClick={() => setActiveTagEditorSection("general")}
            >
              General
            </button>
            {/* <button
              className={`flex-1 py-2 px-4 text-sm font-medium text-center hover:opacity-80 focus:outline-none ${
                activeTagEditorSection === "advanced"
                  ? "border-b-2 border-primary text-primary"
                  : "border-transparent text-gray-500"
              }`}
              onClick={() => setActiveTagEditorSection("advanced")}
            >
              Model Selection
            </button> */}
            <button
              className={`flex-1 py-2 px-4 text-sm font-medium text-center hover:opacity-80 focus:outline-none ${
                activeTagEditorSection === "examples"
                  ? "border-b-2 border-primary text-primary"
                  : "border-transparent text-gray-500"
              }`}
              onClick={() => setActiveTagEditorSection("examples")}
            >
              Training Examples
            </button>
          </div>

          {!isTagEditorCollapsed && (
            <div className="p-4 space-y-4">
              {!tagInEditor ? (
                <div className="text-center text-gray-500">
                  Select a tag to edit
                </div>
              ) : activeTagEditorSection === "general" ? (
                <GeneralSection
                  formData={formData}
                  setFormData={setFormData}
                  handleInputChange={handleInputChange}
                  handleSave={handleSave}
                  saving={saving}
                />
              ) : activeTagEditorSection === "examples" ? (
                <ExamplesSection />
              ) : (
                <></>
              )}
            </div>
          )}
        </div>

        <div className="p-4">
          <button
            onClick={() => !saving && handleSave()}
            className={`w-full btn btn-primary`}
            disabled={
              !formData.name ||
              !formData.description ||
              !formData.output_type ||
              saving
            }
          >
            {saving ? "Saving..." : "Save Changes"}
          </button>
        </div>
      </div>
    </div>
  );
};

const TagEditorHeader = ({
  isTagEditorCollapsed,
  setIsTagEditorCollapsed,
  collapseDirection,
  labelStyles,
  labelRotation,
  headerClasses,
  disableCollapse,
}) => {
  return (
    <>
      <div
        className={headerClasses}
        onClick={() =>
          !disableCollapse && setIsTagEditorCollapsed(!isTagEditorCollapsed)
        }
      >
        {(!isTagEditorCollapsed || collapseDirection === "vertical") && (
          <h2 className="font-semibold">Tag Editor</h2>
        )}
        {!disableCollapse && (
          <button className="text-gray-500 hover:text-gray-700">
            {collapseDirection === "vertical" ? (
              isTagEditorCollapsed ? (
                <FaChevronDown />
              ) : (
                <FaChevronUp />
              )
            ) : isTagEditorCollapsed ? (
              <FaChevronLeft />
            ) : (
              <FaChevronRight />
            )}
          </button>
        )}
      </div>
      {isTagEditorCollapsed && collapseDirection === "horizontal" && (
        <div className="flex justify-center items-center p-4">
          <h2
            className={`whitespace-nowrap font-semibold ${labelRotation}`}
            style={labelStyles}
          >
            Tag Editor
          </h2>
        </div>
      )}
    </>
  );
};

export default TagEditor;
