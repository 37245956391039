import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { Tooltip as CustomTooltip } from "./MetadataOverview";

const FileNodeCount = ({ fileToNodeCount }) => {
  const data = Object.entries(fileToNodeCount || {})
    .map(([name, count]) => ({
      name: name.split("/").pop(), // Only show filename
      count,
      fullName: name,
    }))
    .sort((a, b) => b.count - a.count); // Sort by count descending

  return (
    <div className="h-[400px]">
      <div className="flex items-center mb-2">
        <h3 className="text-lg font-semibold">File Node Count</h3>
        <CustomTooltip text="Distribution of nodes across source files in the vector database" />
      </div>
      <div className="overflow-y-auto pr-4" style={{ height: "350px" }}>
        <div
          style={{
            height: `${Math.max(350, data.length * 40)}px`,
            width: "calc(100% - 20px)",
          }}
        >
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              data={data}
              layout="vertical"
              margin={{ top: 5, right: 60, left: 0, bottom: 5 }}
            >
              <XAxis type="number" hide={true} />
              <YAxis
                type="category"
                dataKey="name"
                width={180}
                tick={{ fill: "#000000", fontSize: "12px" }}
                tickMargin={5}
              />
              <Tooltip
                content={({ payload }) => {
                  if (!payload?.length) return null;
                  const data = payload[0].payload;
                  return (
                    <div className="bg-white p-2 border rounded shadow">
                      <p className="font-semibold text-black">
                        {data.fullName}
                      </p>
                      <p className="text-black">{`${data.count} nodes`}</p>
                    </div>
                  );
                }}
              />
              <Bar
                dataKey="count"
                fill="#10B981"
                radius={[0, 4, 4, 0]}
                label={{
                  position: "right",
                  formatter: (value) => value,
                  fontSize: 12,
                  fill: "#000000",
                  dx: 5,
                }}
              />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
};

export default FileNodeCount;
