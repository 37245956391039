import React, { useState, useEffect, useCallback } from "react";
import { tokenService } from "../../../services/api";
import { auth } from "../../../config/firebase";
import TokenTable from "./components/TokenTable";
import TokenCreationModal from "./components/TokenCreationModal";
import AddCreditsModal from "./components/AddCreditsModal";
import { useContext } from "react";
import { TokenContext } from "../../../contexts/TokenContext";

function TokenDashboard() {
  const { tokens, userCredits, setTokens, setUserCredits } =
    useContext(TokenContext);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [newToken, setNewToken] = useState(null);
  const [showAddCreditsModal, setShowAddCreditsModal] = useState(false);
  const [deletingToken, setDeletingToken] = useState(false);
  const [initialLoadDone, setInitialLoadDone] = useState(false);
  const [showCreditsInfo, setShowCreditsInfo] = useState(false);

  const loadTokens = useCallback(async () => {
    try {
      const response = await tokenService.listTokens();
      setTokens(
        Array.isArray(response.data.tokens) ? response.data.tokens : [],
      );
      setUserCredits(response.data.available_credits);
    } catch (err) {
      setError("Failed to load api keys");
      setTokens([]);
    } finally {
      setLoading(false);
      setInitialLoadDone(true);
    }
  }, [setTokens, setUserCredits]);

  useEffect(() => {
    if (!initialLoadDone) {
      loadTokens();
    }
  }, [loadTokens, initialLoadDone]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const sessionId = urlParams.get("session_id");

    if (sessionId) {
      const verifyPayment = async () => {
        try {
          await tokenService.verifyPayment(sessionId);
          await loadTokens();
        } catch (err) {
          setError("Failed to verify payment.");
        } finally {
          window.history.replaceState({}, "", "/token");
        }
      };

      verifyPayment();
    }
  }, [loadTokens]);

  const handleCreateToken = async () => {
    try {
      const userEmail = auth.currentUser.email;
      const response = await tokenService.createToken(userEmail);
      setNewToken(response.data);
      await loadTokens();
    } catch (err) {
      setError("Failed to create an api key");
    }
  };

  const handleDeleteToken = async (tokenId) => {
    if (deletingToken) return;

    if (!window.confirm("Are you sure you want to delete this token?")) {
      return;
    }

    try {
      setDeletingToken(true);
      await tokenService.deleteToken(tokenId);
      await loadTokens(); // Refresh the token list
    } catch (err) {
      console.error("Delete api key error:", err);
      setError("Failed to delete api key");
    } finally {
      setDeletingToken(false);
    }
  };

  if (loading) {
    return (
      <div className="flex flex-col items-center justify-center min-h-[50vh] gap-4">
        <div className="w-12 h-12 border-4 border-emerald-200 border-t-emerald-500 rounded-full animate-spin"></div>
        <p className="text-lg text-gray-600 font-medium">
          Loading your tokens...
        </p>
      </div>
    );
  }

  return (
    <div className="p-8">
      {/* Error message display */}
      {error && (
        <div className="mb-4 p-4 bg-red-50 border border-red-200 text-red-700 rounded-lg">
          {error}
        </div>
      )}

      {/* Credits Section */}
      <h3 className="text-2xl font-semibold mb-6 text-left text-gray-800">
        Credits
      </h3>
      <div className="mb-8 bg-gray-50 p-6 rounded-lg border border-gray-200">
        <div className="flex flex-col gap-6 mb-6">
          <div className="flex">
            <div className="flex flex-col gap-6 text-left">
              <div className="flex items-center">
                <span className="inline-block w-[180px] font-medium text-left text-lg">
                  User:
                </span>
                <span className="text-gray-700 text-lg">
                  {auth.currentUser?.email}
                </span>
              </div>
              <div className="flex items-center">
                <span className="inline-block w-[180px] font-medium text-left text-lg">
                  Credits remaining:
                </span>
                <span className="text-gray-700 text-lg font-semibold">
                  {userCredits.toLocaleString()}
                </span>
                <div className="relative ml-3">
                  <button
                    className="w-6 h-6 flex items-center justify-center text-white bg-gray-500 rounded-full hover:bg-gray-600 transition-colors"
                    onClick={() => setShowCreditsInfo(!showCreditsInfo)}
                  >
                    ?
                  </button>
                  {showCreditsInfo && (
                    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                      <div className="bg-white rounded-lg p-8 max-w-4xl w-full max-h-[90vh] overflow-y-auto">
                        <div className="flex justify-between items-center mb-6">
                          <h3 className="text-xl font-semibold">
                            Credits & Token Usage Information
                          </h3>
                          <button
                            onClick={() => setShowCreditsInfo(false)}
                            className="text-gray-500 hover:text-gray-700"
                          >
                            <svg
                              className="w-6 h-6"
                              fill="none"
                              stroke="currentColor"
                              viewBox="0 0 24 24"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M6 18L18 6M6 6l12 12"
                              />
                            </svg>
                          </button>
                        </div>

                        <div className="space-y-6">
                          <div>
                            <p className="font-medium mb-2">
                              Credit Conversion:
                            </p>
                            <p>1 Deasy credit = 1,000 tokens (cl100k_base)</p>
                            <p className="text-gray-600 text-xs">
                              Note: Token usage is always rounded up to the
                              nearest 1,000
                            </p>
                          </div>

                          <div>
                            <p className="font-medium mb-2">
                              Token Consumption by Endpoint:
                            </p>
                            <table className="w-full border-collapse">
                              <thead>
                                <tr className="bg-gray-50">
                                  <th className="text-left p-2 border border-gray-200 w-1/3">
                                    Endpoint
                                  </th>
                                  <th className="text-left p-2 border border-gray-200 w-2/3">
                                    Token Usage Calculation
                                  </th>
                                </tr>
                              </thead>
                              <tbody className="text-sm">
                                <tr>
                                  <td className="p-2 border border-gray-200">
                                    Classify
                                  </td>
                                  <td className="p-2 border border-gray-200">
                                    Text length × Number of tag batches
                                  </td>
                                </tr>
                                <tr>
                                  <td className="p-2 border border-gray-200">
                                    Generate file level tag
                                  </td>
                                  <td className="p-2 border border-gray-200">
                                    Length of tags (incl. tag values) in file
                                    chunks for a document
                                  </td>
                                </tr>
                                <tr>
                                  <td className="p-2 border border-gray-200">
                                    Contextualize
                                  </td>
                                  <td className="p-2 border border-gray-200">
                                    Length of truncated file summary and file
                                    tags (incl. tag values)
                                  </td>
                                </tr>
                                <tr>
                                  <td className="p-2 border border-gray-200">
                                    Auto create
                                  </td>
                                  <td className="p-2 border border-gray-200">
                                    Length of tags (incl. tag values) used in
                                    auto creation
                                  </td>
                                </tr>
                                <tr>
                                  <td className="p-2 border border-gray-200">
                                    Refine
                                  </td>
                                  <td className="p-2 border border-gray-200">
                                    Length of tag values in points used for
                                    refining
                                  </td>
                                </tr>
                                <tr>
                                  <td className="p-2 border border-gray-200">
                                    Retrieve
                                  </td>
                                  <td className="p-2 border border-gray-200">
                                    Length of reranking query (incl. tags with
                                    tag values and original query)
                                  </td>
                                </tr>
                                <tr>
                                  <td className="p-2 border border-gray-200">
                                    Tag text
                                  </td>
                                  <td className="p-2 border border-gray-200">
                                    Text length × Number of tag batches
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="text-left">
          <button
            onClick={() => setShowAddCreditsModal(true)}
            disabled={tokens.length === 0}
            className={`px-4 py-2 bg-white rounded-lg shadow-md transition-all duration-200 font-medium relative group
              ${
                tokens.length === 0
                  ? "cursor-not-allowed text-gray-400"
                  : "text-teal-600 hover:shadow-lg"
              }`}
          >
            Add credits
            {tokens.length === 0 && (
              <div className="invisible group-hover:visible absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-3 py-1 bg-gray-800 text-white text-sm rounded whitespace-nowrap">
                Add an API key to add credits
              </div>
            )}
          </button>
        </div>
      </div>

      {/* API Keys Section */}
      <div>
        <h3 className="text-2xl font-semibold mb-6 text-left text-gray-800">
          Deasy API keys
        </h3>
        <TokenTable tokens={tokens} onDeleteToken={handleDeleteToken} />
        <div className="text-left">
          <button
            onClick={handleCreateToken}
            className="mt-4 px-4 py-2 bg-white text-teal-600 rounded-lg shadow-md hover:shadow-lg transition-all duration-200 flex items-center space-x-2 font-medium"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
                clipRule="evenodd"
              />
            </svg>
            <span>New API key</span>
          </button>
        </div>
      </div>

      {/* Existing modals */}
      <TokenCreationModal token={newToken} onClose={() => setNewToken(null)} />
      {showAddCreditsModal && (
        <AddCreditsModal onClose={() => setShowAddCreditsModal(false)} />
      )}
    </div>
  );
}

export default TokenDashboard;
