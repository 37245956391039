import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Tooltip,
} from "recharts";
import { Tooltip as CustomTooltip } from "./MetadataOverview";

const CHART_COLOR = "#10B981"; // Tailwind green-500

const TagDistributionChart = ({ distribution, counts }) => {
  const data = Object.entries(distribution || {})
    .map(([name, value]) => ({
      name: name.replace(/_/g, " "),
      value: value * 100,
      count: counts[name] || 0,
    }))
    .sort((a, b) => b.value - a.value);

  return (
    <div className="h-[500px] relative">
      <div className="flex items-center mb-2">
        <h3 className="text-lg font-semibold">Tag Distribution</h3>
        <CustomTooltip text="Distribution of tags across your content" />
      </div>
      <div className="overflow-y-auto pr-4" style={{ height: "450px" }}>
        <div
          style={{
            height: `${Math.max(450, data.length * 50)}px`,
            width: "calc(100% - 20px)",
            paddingBottom: "75px",
          }}
        >
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              data={data}
              layout="vertical"
              margin={{ top: 5, right: 60, left: 0, bottom: 40 }}
            >
              <XAxis
                type="number"
                domain={[0, 100]}
                tickFormatter={(value) => `${value}%`}
                tick={{ fill: "#000000" }}
              />
              <YAxis
                type="category"
                dataKey="name"
                width={180}
                tick={{ fill: "#000000", fontSize: "12px" }}
                tickMargin={5}
              />
              <Tooltip
                content={({ payload }) => {
                  if (!payload?.length) return null;
                  const data = payload[0].payload;
                  return (
                    <div className="bg-white p-2 border rounded shadow">
                      <p className="font-semibold text-black">{data.name}</p>
                      <p className="text-black">{`${
                        data.count
                      } items (${data.value.toFixed(1)}%)`}</p>
                    </div>
                  );
                }}
              />
              <Bar
                dataKey="value"
                fill={CHART_COLOR}
                radius={[0, 4, 4, 0]}
                label={{
                  position: "right",
                  formatter: (value) => `${value.toFixed(1)}%`,
                  fontSize: 12,
                  fill: "#000000",
                  dx: 5,
                }}
              />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
};

export default TagDistributionChart;
