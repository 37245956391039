import { Tooltip } from "./MetadataOverview";

const UnsyncedNodesTable = ({ unsyncedNodes }) => {
  if (!unsyncedNodes || Object.keys(unsyncedNodes).length === 0) {
    return (
      <div className="h-full flex items-center justify-center">
        <p className="text-gray-600">All nodes are synchronized.</p>
      </div>
    );
  }

  return (
    <div className="h-full flex flex-col max-h-full">
      <div className="flex items-center gap-2 mb-4">
        <h3 className="text-lg font-semibold">Unsynced Nodes</h3>
        <Tooltip text="Nodes that exist in your database but are not synced to Deasy for optimal retrieval" />
      </div>

      <div className="bg-yellow-50 border border-yellow-200 rounded-md p-3 mb-4">
        <p className="text-sm text-yellow-700">
          Found {Object.keys(unsyncedNodes).length} files with{" "}
          {Object.values(unsyncedNodes).reduce(
            (acc, nodes) => acc + nodes.length,
            0,
          )}{" "}
          nodes that need synchronization
        </p>
      </div>

      <div className="flex-1 min-h-0">
        <div className="h-full overflow-y-auto">
          <table className="w-full border-collapse">
            <thead>
              <tr className="bg-gray-50 sticky top-0 z-10">
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider border-b w-1/3">
                  File Name
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider border-b w-2/3">
                  Unsynced Nodes
                </th>
              </tr>
            </thead>
            <tbody className="bg-white">
              {Object.entries(unsyncedNodes).map(([fileName, nodes]) => (
                <tr
                  key={fileName}
                  className="hover:bg-gray-50 border-b border-gray-100 last:border-b-0"
                >
                  <td className="px-6 py-4 text-sm text-gray-900 align-top">
                    <div className="break-all">{fileName}</div>
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-600 font-mono align-top">
                    <div className="break-all">{nodes.join(", ")}</div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default UnsyncedNodesTable;
