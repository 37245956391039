import { BaseContext } from "../../../contexts/BaseContext";
import { useContext, useEffect, useState, useCallback } from "react";
import { prepareVectorDBConfiguration } from "../../../services/utils";
import { metadataService } from "../../../services/api";
import MetadataOverview from "./components/MetadataOverview";
import TagDistributionChart from "./components/TagDistributionChart";
import FileNodeCount from "./components/FileNodeCount";
import MissingTagsTable from "./components/MissingTagsTable";
import UnsyncedNodesTable from "./components/UnsyncedNodesTable";
import LoadingSpinner from "../Utils/LoadingSpinner";
import { RefreshCw } from "lucide-react";

const Metadata = () => {
  const {
    vectorDBConfiguration,
    metadataHealth,
    setMetadataHealth,
    deasyApiKey,
  } = useContext(BaseContext);
  const [isLoading, setIsLoading] = useState(true);
  const [isReloading, setIsReloading] = useState(false);

  const getMetadataHealth = useCallback(async () => {
    setIsReloading(true);
    try {
      const health = await metadataService.getMetadataHealth(
        prepareVectorDBConfiguration(vectorDBConfiguration),
        deasyApiKey,
      );
      setMetadataHealth(health.data);
    } catch (error) {
      console.error("Error fetching metadata health:", error);
    } finally {
      setIsLoading(false);
      setIsReloading(false);
    }
  }, [vectorDBConfiguration, deasyApiKey, setMetadataHealth]);

  // Initial load
  useEffect(() => {
    if (!metadataHealth) {
      setIsLoading(true);
      getMetadataHealth();
    } else {
      setIsLoading(false);
    }
  }, [metadataHealth, getMetadataHealth]);

  // Auto-refresh every 20 seconds
  useEffect(() => {
    const intervalId = setInterval(() => {
      getMetadataHealth();
    }, 20000); // 20 seconds

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, [getMetadataHealth]);

  if (isLoading) {
    return (
      <div className="h-full overflow-y-auto">
        <LoadingSpinner message="Loading metadata analytics..." />
      </div>
    );
  }

  const cleanVDBType = () => {
    const nameMapping = {
      QdrantVectorDBManager: "Qdrant",
      PineconeVectorDBManager: "Pinecone",
      ChromaVectorDBManager: "Chroma",
    };
    return (
      nameMapping[vectorDBConfiguration.type] || vectorDBConfiguration.type
    );
  };

  return (
    <div className="h-full overflow-y-auto" style={{ padding: "20px" }}>
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-semibold text-left">
          Vector Database: {cleanVDBType()}
          <br />
          Collection: {vectorDBConfiguration.collection}
        </h2>

        <button
          onClick={getMetadataHealth}
          className="flex items-center gap-2 px-4 py-2 bg-emerald-600 text-white rounded-lg hover:bg-emerald-700 transition-colors"
          disabled={isReloading}
        >
          <RefreshCw
            className={`w-4 h-4 ${isReloading ? "animate-spin" : ""}`}
          />
          Reload
        </button>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className="col-span-1 md:col-span-2">
          <MetadataOverview
            totalTags={metadataHealth?.total_metadata_tags}
            uniqueTags={metadataHealth?.unique_metadata_tags}
            healthScore={metadataHealth?.metadata_healthiness_score}
          />
        </div>

        <div className="bg-emerald-200 rounded-lg shadow p-6 overflow-hidden h-[400px]">
          <TagDistributionChart
            distribution={metadataHealth?.metadata_tag_distribution}
            counts={metadataHealth?.metadata_tag_counts}
          />
        </div>

        <div className="bg-emerald-200 rounded-lg shadow p-6 overflow-hidden h-[400px]">
          <FileNodeCount fileToNodeCount={metadataHealth?.file_to_node_count} />
        </div>

        <div className="bg-emerald-200 rounded-lg shadow p-6 overflow-hidden h-[400px]">
          <MissingTagsTable
            nodeToMissingTags={metadataHealth?.node_to_missing_tags}
          />
        </div>

        <div className="bg-emerald-200 rounded-lg shadow p-6 overflow-hidden h-[400px]">
          <UnsyncedNodesTable unsyncedNodes={metadataHealth?.unsynced_points} />
        </div>
      </div>
    </div>
  );
};

export default Metadata;
