import FileSelectionDropdown from "./components/FileSelectionDropdown";
import TaggingStudioOutput from "./components/TaggingStudioOutput";
import ActionBar from "./components/ActionBar";
import { useState } from "react";
import TagEditor from "../SavedTags/components/TagEditor/TagEditor";
import TagSelector from "../SavedTags/components/TagSelector";

const TaggingStudio = () => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isSetupOpen, setIsSetupOpen] = useState(true);
  const [isTagSelectorExpanded, setIsTagSelectorExpanded] = useState(false);

  return (
    <div className="flex flex-col min-h-full relative pb-20">
      {isTagSelectorExpanded && (
        <>
          <div
            className="fixed inset-0 bg-black bg-opacity-50 z-40"
            onClick={() => setIsTagSelectorExpanded(false)}
          />
          <div className="fixed left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 z-50 w-[90%] h-[90%] max-w-7xl bg-white rounded-xl shadow-2xl overflow-hidden">
            <button
              onClick={() => setIsTagSelectorExpanded(false)}
              className="absolute top-2 right-2 p-2 bg-white rounded-full shadow-md text-gray-500 hover:text-gray-700 transition-colors duration-200 z-50"
              title="Collapse"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
            <div className="h-full">
              <TagSelector expanded={true} />
            </div>
          </div>
        </>
      )}

      <div className="flex-1 flex flex-col gap-6 p-6">
        <div className="bg-white rounded-xl shadow-sm border border-gray-200">
          <button
            onClick={() => setIsSetupOpen(!isSetupOpen)}
            className="w-full px-6 py-4 text-left font-medium flex items-center justify-between hover:bg-gray-50 rounded-xl transition-colors duration-200"
          >
            <div className="flex items-center gap-3">
              <h2 className="text-xl font-semibold text-gray-800">
                Arrange Tag Test
              </h2>
            </div>
            <span
              className="transform transition-transform duration-200 text-gray-400"
              style={{ transform: isSetupOpen ? "rotate(180deg)" : "" }}
            >
              ▼
            </span>
          </button>

          <div
            className={`transition-all duration-300 ease-in-out overflow-hidden ${
              isSetupOpen ? "opacity-100" : "max-h-0 opacity-0"
            }`}
          >
            <div className="p-6 border-t border-gray-100">
              <div className="grid grid-cols-3 gap-x-8 mb-4">
                <div className="flex flex-col gap-y-2">
                  <h3 className="text-lg font-medium">1. Select tag</h3>
                  <p className="text-sm text-gray-500">
                    Select a tag from the list of saved tags.
                  </p>
                </div>
                <div className="flex flex-col gap-y-2">
                  <h3 className="text-lg font-medium">2. Configure your tag</h3>
                  <p className="text-sm text-gray-500">
                    Configure the tag to your liking.
                  </p>
                </div>
                <div className="flex flex-col gap-y-2">
                  <h3 className="text-lg font-medium">
                    3. Select testing data
                  </h3>
                  <p className="text-sm text-gray-500">
                    Select the files you want to test the tag on.
                  </p>
                </div>
              </div>

              <div className="grid grid-cols-3 gap-x-8">
                <div className="relative bg-white rounded-lg border border-gray-200 shadow-sm overflow-hidden max-h-[90vh]">
                  <button
                    onClick={() => setIsTagSelectorExpanded(true)}
                    className="absolute top-2 right-2 p-2 bg-white rounded-full shadow-md text-gray-500 hover:text-gray-700 transition-colors duration-200"
                    title="Expand"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M4 8V4m0 0h4M4 4l5 5m11-1V4m0 0h-4m4 0l-5 5M4 16v4m0 0h4m-4 0l5-5m11 5l-5-5m5 5v-4m0 4h-4"
                      />
                    </svg>
                  </button>
                  <TagSelector expanded={false} />
                </div>

                <div className="bg-white rounded-lg border border-gray-200 shadow-sm overflow-hidden">
                  <TagEditor
                    collapseDirection="vertical"
                    disableCollapse={true}
                  />
                </div>

                <div className="bg-white rounded-lg border border-gray-200 shadow-sm overflow-hidden">
                  <FileSelectionDropdown
                    selectedFiles={selectedFiles}
                    setSelectedFiles={setSelectedFiles}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <TaggingStudioOutput />
      </div>
      <ActionBar selectedFiles={selectedFiles} />
    </div>
  );
};

export default TaggingStudio;
