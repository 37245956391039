import { useState, useContext, useRef } from "react";
import { BaseContext } from "../../../contexts/BaseContext";
import { metadataService } from "../../../services/api";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import {
  FiSearch,
  FiPlus,
  FiChevronDown,
  FiChevronRight,
  FiDownload,
  FiX,
} from "react-icons/fi";
import { IoSaveOutline } from "react-icons/io5";
import toast from "react-hot-toast";
import LoadingState from "../../Common/LoadingState";
import { prepareVectorDBConfiguration } from "../../../services/utils";
import FileList from "../Utils/FileList";
import { prepareEndpointManagerConfig } from "../../../services/utils";

const AutoCreate = () => {
  const {
    deasyApiKey,
    vectorDBConfiguration,
    setSavedTags,
    llmEndpointConfiguration,
  } = useContext(BaseContext);

  // Add all missing state variables
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [segments, setSegments] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedTags, setSelectedTags] = useState(new Set());
  const [expandedSegments, setExpandedSegments] = useState(new Set());
  const [showAdvancedConfig, setShowAdvancedConfig] = useState(false);
  const [formData, setFormData] = useState({
    n_clusters: null,
    tag_context: "",
    ignore_untagged_files: true,
  });
  const [questions, setQuestions] = useState([]);
  const [newQuestion, setNewQuestion] = useState("");
  const [isFilelevelEnabled, setFilelevelenabled] = useState(true);
  const [saving, setSaving] = useState(false);

  // Add ref for scrolling
  const resultsRef = useRef(null);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };
  const handleChange = () => {
    setFilelevelenabled(!isFilelevelEnabled);
  };

  const handleExampleQuestionsFileChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      try {
        const data = await file.arrayBuffer();
        const workbook = XLSX.read(data, { type: "array" });
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        const jsonData = XLSX.utils.sheet_to_json(worksheet);
        setQuestions(jsonData.map((row) => row.Question).filter(Boolean));
      } catch (e) {
        setError("Failed to parse the example questions file");
      }
    }
  };

  const handleAddQuestion = () => {
    if (newQuestion.trim()) {
      setQuestions((prev) => [...prev, newQuestion.trim()]);
      setNewQuestion("");
    }
  };

  const handleRemoveQuestion = (index) => {
    setQuestions((prev) => prev.filter((_, i) => i !== index));
  };

  const handleDownloadTemplate = () => {
    const ws = XLSX.utils.json_to_sheet([
      { Question: "Enter your question here" },
    ]);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Example Questions");
    const wbout = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    saveAs(
      new Blob([wbout], { type: "application/octet-stream" }),
      "example_questions_template.xlsx",
    );
  };

  const handleAutoCreate = async () => {
    if (selectedFiles.length === 0) {
      setError("Please select at least one file");
      return;
    }

    setLoading(true);
    setError(null);

    const requestBody = {
      vector_db_config: prepareVectorDBConfiguration(vectorDBConfiguration),
      endpoint_manager_config: prepareEndpointManagerConfig(
        llmEndpointConfiguration,
      ),
      file_names: selectedFiles,
      tag_context: formData.tag_context || undefined,
      example_questions: questions.length > 0 ? questions : undefined,
      ignore_untagged_files: formData.ignore_untagged_files,
      use_file_level_tags: isFilelevelEnabled,
    };

    if (formData.n_clusters !== null) {
      requestBody.n_clusters = formData.n_clusters;
    }

    try {
      const response = await metadataService.autoCreateTags(
        requestBody,
        deasyApiKey,
      );
      setSegments(response.data.tags);

      console.log(response.data.tags);

      // Scroll to results after a short delay to ensure rendering is complete
      setTimeout(() => {
        resultsRef.current?.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }, 100);
    } catch (err) {
      const errorMessage = err.response?.data?.detail
        ? typeof err.response.data.detail === "object"
          ? err.response.data.detail.error ||
            JSON.stringify(err.response.data.detail)
          : err.response.data.detail
        : "Failed to auto-create tags";
      setError(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  // Update the search and filter functions
  const searchTags = (searchTerms, tagName, tagData) => {
    if (searchTerms.length === 0) return true;

    return searchTerms.every((term) => {
      const searchTerm = term.toLowerCase();
      return (
        tagName.toLowerCase().includes(searchTerm) ||
        tagData.description?.toLowerCase().includes(searchTerm)
      );
    });
  };

  // Updated filter function that only shows segments with matching tags
  const filterSegments = (segments) => {
    if (!segments) return [];

    const searchTerms = searchQuery
      .toLowerCase()
      .trim()
      .split(/\s+/)
      .filter((term) => term.length > 0);

    // Filter and transform segments
    const filteredSegments = Object.entries(segments)
      .map(([name, data]) => {
        // Filter tags within each segment
        const filteredTags = Object.entries(data.tags).filter(
          ([tagName, tagData]) => searchTags(searchTerms, tagName, tagData),
        );

        // Return segment with filtered tags
        return [
          name,
          {
            ...data,
            tags: Object.fromEntries(filteredTags),
          },
        ];
      })
      .filter(([_, data]) => Object.keys(data.tags).length > 0); // Only keep segments with matching tags

    return filteredSegments;
  };

  const handleTagSelect = (segmentName, tagName) => {
    const tagKey = `${segmentName}:${tagName}`;
    setSelectedTags((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(tagKey)) {
        newSet.delete(tagKey);
      } else {
        newSet.add(tagKey);
      }
      return newSet;
    });
  };

  const handleSaveTags = async () => {
    if (!segments || selectedTags.size === 0) return;

    setSaving(true);
    const errors = [];

    try {
      // Process each selected tag
      for (const tagKey of selectedTags) {
        const [segmentName, tagName] = tagKey.split(":");
        const tagData = segments[segmentName].tags[tagName];

        // Prepare tag data in the format expected by createTag
        const tagToSave = {
          name: tagData.name,
          description: tagData.description || tagData.name,
          output_type: tagData.output_type || "word",
          available_values: tagData.availableValues || [],
          option:
            tagData.availableValues?.length === 2 &&
            tagData.availableValues.includes("Yes") &&
            tagData.availableValues.includes("No")
              ? "yesNo"
              : tagData.availableValues?.length > 0
                ? "custom"
                : "aiGenerated",
          metadata: {
            created_at: new Date().toISOString(),
            source: "auto-created",
            segment: segmentName,
          },
        };

        try {
          // Create the tag using the metadata service
          await metadataService.createTag(tagToSave, deasyApiKey);
        } catch (error) {
          errors.push(`Failed to save tag "${tagName}": ${error.message}`);
        }
      }

      // Refresh the saved tags list
      const response = await metadataService.getSavedTags(deasyApiKey);
      setSavedTags(response.data.tags || []);

      // Show success message
      toast.success(
        <div className="flex flex-col gap-1">
          <span className="font-medium">Successfully saved tags!</span>
          <span className="text-sm text-gray-600">
            Added {selectedTags.size - errors.length} new tag
            {selectedTags.size - errors.length !== 1 ? "s" : ""} to your
            collection
          </span>
        </div>,
        {
          duration: 4000,
          position: "top-right",
          style: {
            background: "#10B981",
            color: "white",
            padding: "16px",
          },
          icon: "✨",
        },
      );

      // Show errors if any
      if (errors.length > 0) {
        toast.error(
          <div className="flex flex-col gap-1">
            <span className="font-medium">Some tags failed to save</span>
            <span className="text-sm">
              {errors.length} error{errors.length !== 1 ? "s" : ""} occurred
            </span>
          </div>,
          {
            duration: 6000,
            position: "top-right",
          },
        );
      }

      // Clear selection after saving
      setSelectedTags(new Set());
    } catch (error) {
      console.error("Error saving tags:", error);
      toast.error("Failed to save tags. Please try again.");
    } finally {
      setSaving(false);
    }
  };

  // Update the output type display
  const getOutputType = (tagValue) => {
    // Keep the original output_type (word, number, date)
    return tagValue.output_type || "word";
  };

  // Add toggle function
  const toggleSegment = (segmentName) => {
    setExpandedSegments((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(segmentName)) {
        newSet.delete(segmentName);
      } else {
        newSet.add(segmentName);
      }
      return newSet;
    });
  };

  // Add this helper function to count total matching tags
  const getTotalMatchingTags = (segments, searchTerms) => {
    if (!segments) return 0;

    return Object.values(segments).reduce((total, segment) => {
      const matchingTags = Object.entries(segment.tags).filter(
        ([tagName, tagData]) => searchTags(searchTerms, tagName, tagData),
      ).length;
      return total + matchingTags;
    }, 0);
  };

  // Add these helper functions
  const handleSelectAllTags = (segments) => {
    const allTags = new Set();
    Object.entries(segments).forEach(([segmentName, data]) => {
      Object.keys(data.tags).forEach((tagName) => {
        allTags.add(`${segmentName}:${tagName}`);
      });
    });
    setSelectedTags(allTags);
  };

  const handleClearAllTags = () => {
    setSelectedTags(new Set());
  };

  return (
    <div className="p-4">
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
        {/* Left Column - add max-height and overflow */}
        <div className="bg-white rounded-lg shadow-md p-6 max-h-[800px] overflow-auto">
          <h3 className="text-lg font-medium mb-4">1. Select Files</h3>
          <FileList
            selectedFiles={selectedFiles}
            setSelectedFiles={setSelectedFiles}
            setError={setError}
            showTitle={true}
          />
          <div className="ml-4 flex items-center gap-2">
            <input
              type="checkbox"
              checked={isFilelevelEnabled}
              onChange={handleChange}
              className="w-4 h-4 cursor-pointer"
            />
            <label className="text-sm text-gray-600 cursor-pointer">
              Use file level tags?
            </label>
          </div>
        </div>

        {/* Right Column - add max-height and overflow */}
        <div className="bg-white rounded-lg shadow-md p-6 max-h-[800px] overflow-auto">
          <div className="flex flex-col gap-2 mb-6">
            <div className="flex justify-between items-center">
              <h3 className="text-lg font-medium">2. Configuration</h3>
              <button
                onClick={() => setShowAdvancedConfig(!showAdvancedConfig)}
                className={`px-4 py-2 rounded-full transition-all duration-200 flex items-center gap-2
                  ${
                    showAdvancedConfig
                      ? "bg-blue-100 text-secondary hover:bg-blue-200"
                      : "bg-gray-100 text-gray-600 hover:bg-gray-200"
                  }`}
              >
                {showAdvancedConfig ? (
                  <>
                    <span>Using Custom Settings</span>
                    <span className="text-xs bg-blue-200 px-2 py-1 rounded-full">
                      Active
                    </span>
                  </>
                ) : (
                  <>
                    <span>Using Default Settings</span>
                    <span className="text-xs bg-gray-200 px-2 py-1 rounded-full">
                      Recommended
                    </span>
                  </>
                )}
              </button>
            </div>
            {!showAdvancedConfig && (
              <div className="text-sm text-gray-600 bg-gray-50 p-3 rounded-md">
                Using recommended settings: auto-detect clusters, no specific
                context, ignore missing tags on some file chunks.
                <button
                  onClick={() => setShowAdvancedConfig(true)}
                  className="text-blue-600 hover:text-blue-800 ml-1 underline"
                >
                  Customize settings
                </button>
              </div>
            )}
          </div>

          <div
            className={`space-y-4 transition-all duration-200 
            ${
              !showAdvancedConfig
                ? "opacity-20 pointer-events-none filter blur-[3px]"
                : "opacity-100"
            }`}
          >
            {/* Number of Clusters */}
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700 mb-1 text-left">
                Number of Clusters
              </label>
              <input
                type="number"
                name="n_clusters"
                value={formData.n_clusters}
                onChange={handleInputChange}
                className="input input-bordered w-full"
                min="1"
              />
            </div>

            {/* Tag Context */}
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700 mb-1 text-left">
                Tag Context
              </label>
              <textarea
                name="tag_context"
                value={formData.tag_context}
                onChange={handleInputChange}
                className="textarea textarea-bordered w-full border-2 p-2 rounded-sm"
                placeholder="Provide context for tag generation..."
                rows="3"
              />
            </div>

            {/* Example Questions */}
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700 mb-1 text-left">
                Example Questions
              </label>

              {/* File Upload and Template */}
              <div className="flex gap-2 mb-2">
                <input
                  type="file"
                  accept=".xlsx"
                  onChange={handleExampleQuestionsFileChange}
                  className="file-input file-input-bordered w-full"
                />
                <button
                  onClick={handleDownloadTemplate}
                  className="btn btn-square btn-outline"
                  title="Download Template"
                >
                  <FiDownload />
                </button>
              </div>

              {/* Manual Question Input */}
              <div className="flex gap-2 mb-2">
                <input
                  type="text"
                  value={newQuestion}
                  onChange={(e) => setNewQuestion(e.target.value)}
                  onKeyPress={(e) => e.key === "Enter" && handleAddQuestion()}
                  placeholder="Type a question and press Enter"
                  className="input input-bordered w-full"
                />
                <button
                  onClick={handleAddQuestion}
                  className="btn btn-square btn-outline"
                  disabled={!newQuestion.trim()}
                >
                  <FiPlus />
                </button>
              </div>

              {/* Questions List */}
              <div className="max-h-[200px] overflow-y-auto">
                {questions.map((question, index) => (
                  <div
                    key={index}
                    className="flex justify-between items-center p-2 hover:bg-gray-50 rounded"
                  >
                    <span className="text-sm">{question}</span>
                    <button
                      onClick={() => handleRemoveQuestion(index)}
                      className="text-red-500 hover:text-red-700"
                    >
                      <FiX />
                    </button>
                  </div>
                ))}
              </div>
            </div>

            {/* Ignore Untagged Files */}
            <div className="mb-6">
              <label className="flex items-center gap-2 cursor-pointer">
                <input
                  type="checkbox"
                  name="ignore_untagged_files"
                  checked={formData.ignore_untagged_files}
                  onChange={handleInputChange}
                  className="checkbox"
                />
                <span className="text-sm text-gray-700">
                  Ignore untagged files
                </span>
              </label>
            </div>
          </div>

          {/* Action Button - moved outside the disabled section */}
          <div className="mt-6 space-y-4">
            <button
              onClick={handleAutoCreate}
              disabled={loading || selectedFiles.length === 0}
              className={`btn w-full h-12 text-lg ${
                selectedFiles.length === 0
                  ? "btn-disabled bg-gray-300 text-gray-500"
                  : "btn-primary"
              }`}
            >
              {loading ? "Processing files..." : "Auto Create Tags"}
            </button>
            {selectedFiles.length === 0 && (
              <p className="text-sm text-center text-gray-600">
                Please select files to continue
              </p>
            )}
          </div>
        </div>
      </div>

      {/* Error Message */}
      {error && (
        <div className="mt-6 p-4 bg-red-100 text-red-700 rounded-md">
          {typeof error === "string" ? error : JSON.stringify(error)}
        </div>
      )}

      {/* Results Section */}
      {loading ? (
        <div className="mt-6">
          <LoadingState text="Generating tags..." size="large" theme="light" />
        </div>
      ) : (
        segments && (
          <div
            ref={resultsRef}
            className="mt-6 bg-white rounded-lg shadow-md p-6"
          >
            {/* Sticky Header with Save Button and Search */}
            <div className="sticky top-0 z-20 bg-white py-4 -mx-6 px-6 border-b border-gray-200">
              <div className="flex justify-between items-center mb-4">
                <div className="flex items-center gap-2">
                  <h3 className="text-lg font-medium">
                    3. Generated Tag Categories
                  </h3>
                  <div className="animate-bounce text-primary">↓</div>
                </div>
                <div className="flex items-center gap-2">
                  <div className="flex gap-2 mr-4">
                    <button
                      onClick={() => handleSelectAllTags(segments)}
                      className="btn btn-sm btn-outline"
                      disabled={!segments || Object.keys(segments).length === 0}
                    >
                      Select All
                    </button>
                    <button
                      onClick={handleClearAllTags}
                      className="btn btn-sm btn-outline"
                      disabled={selectedTags.size === 0}
                    >
                      Clear All
                    </button>
                  </div>
                  <button
                    onClick={handleSaveTags}
                    disabled={selectedTags.size === 0 || saving}
                    className={`btn btn-primary gap-2 ${
                      selectedTags.size === 0 || saving
                        ? "opacity-50"
                        : "hover:bg-primary-dark"
                    }`}
                  >
                    <IoSaveOutline className="w-5 h-5" />
                    {saving
                      ? "Saving..."
                      : `Save Selected Tags (${selectedTags.size})`}
                  </button>
                </div>
              </div>

              {/* Simplified Search */}
              <div className="flex items-center gap-4">
                <div className="relative flex-1">
                  <FiSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                  <input
                    type="text"
                    placeholder="Search across all fields..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    className="input input-bordered w-full pl-10"
                  />
                </div>
                {searchQuery && (
                  <span className="text-sm text-gray-600">
                    {getTotalMatchingTags(
                      segments,
                      searchQuery
                        .toLowerCase()
                        .trim()
                        .split(/\s+/)
                        .filter((term) => term.length > 0),
                    )}{" "}
                    matching tags
                  </span>
                )}
              </div>
            </div>

            {/* Results Content - Remove overflow-auto from parent div */}
            <div className="mt-6">
              <div className="grid grid-cols-1 gap-4">
                {filterSegments(segments).map(([name, data]) => (
                  <div
                    key={name}
                    className="bg-white rounded-xl border border-gray-200 hover:shadow-lg transition-all duration-200"
                  >
                    {/* Segment Header - Now Clickable */}
                    <div
                      className="bg-primary bg-opacity-10 p-4 border-b border-gray-200 cursor-pointer hover:bg-opacity-20 transition-all"
                      onClick={() => toggleSegment(name)}
                    >
                      <div className="flex items-center justify-between">
                        <div className="flex items-center gap-2">
                          {expandedSegments.has(name) ? (
                            <FiChevronDown className="w-5 h-5 text-primary" />
                          ) : (
                            <FiChevronRight className="w-5 h-5 text-primary" />
                          )}
                          <h4 className="text-xl font-semibold text-primary">
                            {name.replace(/_/g, " ")}
                          </h4>
                        </div>
                        <span className="text-sm text-gray-600">
                          {Object.keys(data.tags).length} tags
                        </span>
                      </div>
                      <p className="mt-2 text-gray-600 leading-relaxed pl-7">
                        {data.description}
                      </p>
                    </div>

                    {/* Tags Content - Collapsible */}
                    {expandedSegments.has(name) && (
                      <div className="p-4">
                        <div className="space-y-4 justify-start text-left">
                          {Object.entries(data.tags).map(
                            ([tagName, tagValue]) => {
                              const tagKey = `${name}:${tagName}`;
                              const isSelected = selectedTags.has(tagKey);

                              // Get option type and format display values
                              const optionType =
                                tagValue.option || "aiGenerated";
                              const availableValues =
                                tagValue.availableValues || [];

                              let optionBadgeStyle = "";

                              // Set option badge style based on option type
                              switch (optionType) {
                                case "yesNo":
                                  optionBadgeStyle =
                                    "bg-green-100 text-green-800";
                                  break;
                                case "aiGenerated":
                                  optionBadgeStyle =
                                    "bg-blue-100 text-blue-800";
                                  break;
                                default:
                                  optionBadgeStyle =
                                    "bg-gray-100 text-gray-800";
                              }

                              return (
                                <div
                                  key={tagName}
                                  onClick={() => handleTagSelect(name, tagName)}
                                  className={`bg-gray-50 rounded-lg p-4 hover:bg-gray-100 transition-all duration-200 cursor-pointer
                                  ${
                                    isSelected
                                      ? "ring-2 ring-primary ring-offset-2"
                                      : ""
                                  }`}
                                >
                                  <div className="flex items-start gap-3">
                                    <div className="flex-1">
                                      <div className="flex items-center justify-between mb-2">
                                        <h5 className="text-sm font-medium text-gray-500">
                                          {tagName.replace(/_/g, " ")}
                                        </h5>
                                        <span
                                          className={`text-xs px-2 py-1 rounded-full ${optionBadgeStyle}`}
                                        >
                                          {optionType === "aiGenerated"
                                            ? "Open Ended"
                                            : optionType}
                                        </span>
                                      </div>

                                      {/* Description */}
                                      <p className="text-sm text-gray-600 mb-2">
                                        {tagValue.description}
                                      </p>

                                      {/* Available Values */}
                                      {availableValues.length > 0 && (
                                        <div className="mt-2">
                                          <p className="text-xs text-gray-500 mb-1">
                                            Available Values:
                                          </p>
                                          <div className="flex flex-wrap gap-1">
                                            {availableValues.map(
                                              (value, index) => (
                                                <span
                                                  key={index}
                                                  className="text-xs bg-primary bg-opacity-10 text-primary px-2 py-1 rounded-full"
                                                >
                                                  {value}
                                                </span>
                                              ),
                                            )}
                                          </div>
                                        </div>
                                      )}

                                      {/* Output Type */}
                                      <div className="mt-2 flex items-center gap-2">
                                        <span className="text-xs text-gray-500">
                                          Output:
                                        </span>
                                        <span
                                          className={`text-xs px-2 py-1 rounded-full ${
                                            {
                                              word: "bg-blue-100 text-blue-800",
                                              number:
                                                "bg-green-100 text-green-800",
                                              date: "bg-purple-100 text-purple-800",
                                            }[getOutputType(tagValue)] ||
                                            "bg-gray-100 text-gray-700"
                                          }`}
                                        >
                                          {getOutputType(tagValue)}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            },
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        )
      )}
    </div>
  );
};

export default AutoCreate;
