import { QuestionMarkCircleIcon } from "@heroicons/react/24/solid";

const MetadataOverview = ({ totalTags, uniqueTags, healthScore }) => {
  const overviewItems = [
    {
      title: "Total Tags",
      value: totalTags,
      tooltip:
        "Total number of metadata tag-value pairs across all nodes in the vector database",
    },
    {
      title: "Unique Tags",
      value: uniqueTags,
      tooltip:
        "Number of distinct Deasy metadata tags utilized across all nodes in the vector database",
    },
    {
      title: "Health Score",
      value: healthScore,
      tooltip:
        "Overall metadata health score based on consistency of metadata presence and distribution in the vector database",
      isHealthScore: true,
    },
  ];

  return (
    <div className="flex gap-4">
      {overviewItems.map((item) => (
        <OverviewCard key={item.title} {...item} />
      ))}
    </div>
  );
};

const OverviewCard = ({ title, value, tooltip, isHealthScore }) => (
  <div className="flex-1 bg-emerald-200 rounded-lg shadow p-6 overflow-visible">
    <div className="flex items-center mb-2">
      <h2 className="text-xl font-semibold">{title}</h2>
      <Tooltip text={tooltip} />
    </div>
    <div className="flex justify-center items-center h-24">
      {isHealthScore ? (
        <HealthScoreCircle score={value} />
      ) : (
        <p className="text-3xl font-bold">{value?.toLocaleString()}</p>
      )}
    </div>
  </div>
);

export const Tooltip = ({ text }) => (
  <div className="relative group ml-2">
    <QuestionMarkCircleIcon className="h-5 w-5 text-gray-400 hover:text-gray-500" />
    <div className="hidden group-hover:block absolute z-[100] w-64 p-2 bg-gray-800 text-white text-sm rounded-md bottom-0 left-5">
      {text}
    </div>
  </div>
);

const HealthScoreCircle = ({ score }) => (
  <div className="relative inline-flex">
    <svg className="w-24 h-24">
      <circle
        className="text-gray-200"
        strokeWidth="5"
        stroke="currentColor"
        fill="transparent"
        r="45"
        cx="48"
        cy="48"
      />
      <circle
        className="text-emerald-500"
        strokeWidth="5"
        strokeDasharray={2 * Math.PI * 45}
        strokeDashoffset={2 * Math.PI * 45 * ((100 - score) / 100)}
        strokeLinecap="round"
        stroke="currentColor"
        fill="transparent"
        r="45"
        cx="48"
        cy="48"
      />
    </svg>
    <span className="absolute inset-0 flex items-center justify-center text-2xl font-bold">
      {Math.round(score)}%
    </span>
  </div>
);

export default MetadataOverview;
