import { useState } from "react";
import { FiPlus, FiSearch } from "react-icons/fi";
import { FaTrashAlt } from "react-icons/fa";
import { useContext } from "react";
import { BaseContext } from "../../../../contexts/BaseContext";
import { metadataService } from "../../../../services/api";

// Utility functions
const getTagType = (tag) => {
  const types = {
    yesNo: { label: "Yes/No", style: "bg-green-100 text-green-800" },
    custom: { label: "Defined values", style: "bg-gray-100 text-blue-800" },
    aiGenerated: { label: "Open ended", style: "bg-gray-100 text-blue-800" },
  };
  return (
    types[tag.option] || {
      label: "Unknown",
      style: "bg-gray-100 text-gray-600",
    }
  );
};

// Sub-components
const TableHeader = ({ searchTerm, setSearchTerm, onNewTag }) => (
  <div className="p-4 flex gap-4 items-center border-b border-gray-200 sticky top-0 bg-white z-10">
    <div className="relative flex-grow">
      <FiSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 w-5 h-5 text-gray-400" />
      <input
        type="text"
        placeholder="Search tags..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 outline-none"
      />
    </div>
    <button
      onClick={onNewTag}
      className="flex items-center gap-2 px-4 py-2 btn bg-white border-2 text-primary border-primary whitespace-nowrap"
    >
      <FiPlus className="w-5 h-5" />
      <span>New Tag</span>
    </button>
  </div>
);

const TagsTable = ({
  columns = ["Name", "Description", "Type", "Available Values", "Actions"],
}) => {
  const {
    savedTags,
    setTagInEditor,
    tagInEditor,
    setSavedTags,
    deasyApiKey,
    setIsTagEditorCollapsed,
  } = useContext(BaseContext);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchTerm, setSearchTerm] = useState("");

  // Ensure savedTags is always an array and handle initial loading state
  const tags = Array.isArray(savedTags) ? savedTags : [];

  const filteredTags = tags.filter(
    (tag) =>
      tag?.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      tag?.description?.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  const totalPages = Math.ceil(filteredTags.length / rowsPerPage);

  const handleEdit = (tag) => {
    // Clear previous selection and set new tag
    const { isNewlySaved, ...cleanTag } = tag;
    setTagInEditor(cleanTag);
    setIsTagEditorCollapsed(false);
  };

  const handleDelete = async (tag) => {
    try {
      if (
        !window.confirm(
          `Are you sure you want to delete the tag "${tag.name}"?`,
        )
      ) {
        return;
      }

      if (tag.tag_id) {
        await metadataService.deleteTag(tag.tag_id, deasyApiKey);
        const response = await metadataService.getSavedTags(deasyApiKey);
        setSavedTags(response.data.tags || []);
      } else {
        setSavedTags((prev) => prev.filter((t) => t.name !== tag.name));
      }

      if (
        tagInEditor?.tag_id === tag.tag_id ||
        tagInEditor?.name === tag.name
      ) {
        setTagInEditor({});
      }
    } catch (error) {
      console.error("Failed to delete tag:", error);
    }
  };

  const handleNewTag = () => {
    setTagInEditor({});
    setIsTagEditorCollapsed(false);
  };

  const renderAvailableValues = (tag) => {
    if (
      !Array.isArray(tag.available_values) ||
      tag.available_values.length === 0
    ) {
      const typeMap = {
        number: "Any number",
        date: "Any date",
        text: "Any text",
      };
      return (
        <span className="text-xs text-gray-500 italic">
          {typeMap[tag.output_type] || "Any text"}
        </span>
      );
    }

    return (
      <div className="flex flex-wrap gap-1">
        {tag.available_values.map((value, idx) => (
          <span
            key={idx}
            className="text-xs bg-gray-100 text-gray-700 px-2 py-1 rounded-full"
          >
            {value}
          </span>
        ))}
      </div>
    );
  };

  return (
    <div className="bg-white rounded-lg shadow-md overflow-hidden h-full flex flex-col">
      <TableHeader
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        onNewTag={handleNewTag}
      />

      <div className="flex-1 overflow-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50 sticky top-0 z-10">
            <tr>
              {columns.map((header) => (
                <th
                  key={header}
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {!Array.isArray(savedTags) ? (
              <tr>
                <td
                  colSpan={columns.length}
                  className="px-6 py-12 text-center text-gray-500"
                >
                  Loading...
                </td>
              </tr>
            ) : filteredTags.length === 0 ? (
              <tr>
                <td
                  colSpan={columns.length}
                  className="px-6 py-12 text-center text-gray-500"
                >
                  No tags found
                </td>
              </tr>
            ) : (
              filteredTags
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((tag) => {
                  const isSelected =
                    (tagInEditor?.tag_id &&
                      tagInEditor.tag_id === tag.tag_id) ||
                    (!tagInEditor?.tag_id && tagInEditor?.name === tag.name);

                  return (
                    <tr
                      key={tag.tag_id || tag.name}
                      className={`hover:bg-gray-50 cursor-pointer ${
                        isSelected ? "bg-green-50" : ""
                      }`}
                      onClick={() => handleEdit(tag)}
                    >
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                        {tag.name}
                      </td>
                      <td className="px-6 py-4 text-sm text-gray-500 text-left">
                        {tag.description}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm">
                        <span
                          className={`inline-flex px-2 py-1 text-xs font-semibold rounded-full ${
                            getTagType(tag).style
                          }`}
                        >
                          {getTagType(tag).label}
                        </span>
                      </td>
                      <td className="px-6 py-4 text-sm">
                        {renderAvailableValues(tag)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                        <div
                          className="flex gap-2 justify-end"
                          onClick={(e) => e.stopPropagation()}
                        >
                          <button
                            onClick={(e) => {
                              e.stopPropagation();
                              handleDelete(tag);
                            }}
                            className="shadow-sm text-red-600 hover:text-red-900 p-1 rounded-full bg-red-50 hover:bg-red-100"
                            title="Delete tag"
                          >
                            <FaTrashAlt className="w-4 h-4" />
                          </button>
                        </div>
                      </td>
                    </tr>
                  );
                })
            )}
          </tbody>
        </table>
      </div>

      <div className="bg-white px-6 py-3 flex items-center justify-between border-t border-gray-200">
        <div className="flex items-center gap-2">
          <span className="text-sm text-gray-700">Rows per page:</span>
          <select
            value={rowsPerPage}
            onChange={(e) => {
              setRowsPerPage(Number(e.target.value));
              setPage(0);
            }}
            className="border border-gray-300 rounded-md text-sm p-1"
          >
            {[5, 10, 25].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            ))}
          </select>
        </div>
        <div className="flex items-center gap-2">
          <button
            onClick={() => setPage(Math.max(0, page - 1))}
            disabled={page === 0}
            className="px-3 py-1 border border-gray-300 rounded-md text-sm disabled:opacity-50 disabled:cursor-not-allowed hover:bg-gray-50"
          >
            Previous
          </button>
          <span className="text-sm text-gray-700">
            Page {page + 1} of {Math.max(1, totalPages)}
          </span>
          <button
            onClick={() => setPage(Math.min(totalPages - 1, page + 1))}
            disabled={page >= totalPages - 1}
            className="px-3 py-1 border border-gray-300 rounded-md text-sm disabled:opacity-50 disabled:cursor-not-allowed hover:bg-gray-50"
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default TagsTable;
