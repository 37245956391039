import React, { useState, useContext } from "react";
import { BaseContext } from "../../../../../contexts/BaseContext";
import { FaExpandAlt, FaCompressAlt, FaTrashAlt } from "react-icons/fa";
import { v4 as uuidv4 } from "uuid";

const ExamplesSection = () => {
  const { tagInEditor, setTagInEditor } = useContext(BaseContext);
  const [isGloballyCollapsed, setIsGloballyCollapsed] = useState(true);

  const positiveExamples = tagInEditor?.examples?.filter(
    (example) => example.isPositive,
  );
  const negativeExamples = tagInEditor?.examples?.filter(
    (example) => !example.isPositive,
  );

  const addExamplePair = ({ isPositive }) => {
    const newExample = {
      id: uuidv4(),
      value: "",
      evidence: "",
      isPositive: isPositive,
    };
    setTagInEditor((prev) => ({
      ...prev,
      examples: [...prev.examples, newExample],
    }));
  };

  const deleteExamplePair = (id) => {
    setTagInEditor((prev) => ({
      ...prev,
      examples: prev.examples.filter((example) => example.id !== id),
    }));
  };

  const updateExamplePair = (id, field, value) => {
    setTagInEditor((prev) => ({
      ...prev,
      examples: prev.examples.map((example) =>
        example.id === id ? { ...example, [field]: value } : example,
      ),
    }));
  };

  return (
    <div className="bg-white overflow-auto hide-scrollbar">
      <h2 className="text-xl font-semibold text-gray-800 mb-4 flex items-center gap-2">
        Training Examples
        {/* <Tooltip text="Provide example input-output pairs to teach the AI how to tag content. This improves accuracy and consistency in tagging." /> */}
      </h2>

      <div className="text-sm text-gray-600 bg-gray-100 border-l-4 border-primary p-4 mb-6 rounded-r-rm text-left">
        <p className="text-sm text-gray-600 text-left">
          For best results:
          <ul className="list-disc ml-5 mt-2 space-y-1">
            <li>Include diverse examples covering different scenarios</li>
            <li>Provide both correct and incorrect examples</li>
            <li>Be specific and consistent in your output format</li>
          </ul>
        </p>
      </div>

      <div className="flex justify-between items-center mb-8">
        <div className="flex space-x-2">
          <button
            onClick={(e) => {
              e.preventDefault();
              addExamplePair({ isPositive: true });
            }}
            className="border-2 border-primary text-primary hover:bg-containerLight font-medium py-1 px-2 rounded-md transition duration-300 text-sm"
          >
            Add Correct Example
          </button>
          <button
            onClick={(e) => {
              e.preventDefault();
              addExamplePair({ isPositive: false });
            }}
            className="border-2 border-gray-300 text-gray-600 hover:bg-gray-100 font-medium py-1 px-2 rounded-md transition duration-300 text-sm"
          >
            Add Incorrect Example
          </button>
          <button
            onClick={() => setIsGloballyCollapsed(!isGloballyCollapsed)}
            className="bg-gray-200 hover:bg-gray-300 text-gray-700 font-medium py-2 px-4 rounded-md transition duration-300"
          >
            {isGloballyCollapsed ? <FaExpandAlt /> : <FaCompressAlt />}
          </button>
        </div>
      </div>

      <div className="max-h-[600px] overflow-y-auto pr-2 hide-scrollbar">
        <ExampleList
          title="Correct Examples"
          examples={positiveExamples}
          updateExamplePair={updateExamplePair}
          deleteExamplePair={deleteExamplePair}
          type="examples"
          isGloballyCollapsed={isGloballyCollapsed}
          isPositive={true}
        />
        <ExampleList
          title="Incorrect Examples"
          examples={negativeExamples}
          updateExamplePair={updateExamplePair}
          deleteExamplePair={deleteExamplePair}
          type="neg_examples"
          isGloballyCollapsed={isGloballyCollapsed}
          isPositive={false}
        />
      </div>
    </div>
  );
};

const ExampleList = ({
  title,
  examples,
  updateExamplePair,
  deleteExamplePair,
  type,
  isGloballyCollapsed,
  isPositive,
}) => {
  if (!examples || examples.length === 0) return null;

  return (
    <div className="mb-8">
      <h3 className="text-lg font-medium text-gray-800 mb-2 flex justify-between items-center">
        <span>{title}</span>
      </h3>
      <p className="text-sm text-gray-600 mb-4 text-left">
        {type === "examples"
          ? "Correct examples help the AI understand what to look for and how to correctly apply the tag."
          : "Incorrect examples teach the AI what not to tag, improving accuracy and reducing false positives."}
      </p>
      {examples.map((example, index) => (
        <ExamplePair
          key={index}
          example={example}
          updateExamplePair={updateExamplePair}
          deleteExamplePair={deleteExamplePair}
          type={type}
          isGloballyCollapsed={isGloballyCollapsed}
          isPositive={isPositive}
        />
      ))}
    </div>
  );
};

const ExamplePair = ({
  example,
  updateExamplePair,
  deleteExamplePair,
  type,
  isGloballyCollapsed,
  isPositive,
}) => {
  const [isExampleCollapsed, setIsExampleCollapsed] = useState(true);
  const [copySuccess, setCopySuccess] = useState({
    evidence: false,
    value: false,
  });

  // when isGloballyCollapsed is False, the example is not collapsed
  // when isGloballyCollapsed is True, the example is collapsed if isExampleCollapsed is True
  const collapsed = isGloballyCollapsed && isExampleCollapsed;

  return (
    <div
      className={`mb-4 p-4 border-l-4 rounded-r-md relative ${
        isPositive ? "border-primary bg-gray-100" : "border-red-200 bg-red-50"
      }`}
    >
      <div className="flex justify-between items-center">
        <div>
          <input
            type="text"
            value={example?.value || ""}
            onChange={(e) => {
              e.preventDefault();
              updateExamplePair(example.id, "value", e.target.value);
            }}
            placeholder="Example name"
            className="text-sm font-medium p-1 border rounded"
          />
        </div>
        <div className="flex items-center space-x-2">
          <button
            onClick={(e) => {
              e.preventDefault();
              setIsExampleCollapsed(!isExampleCollapsed);
            }}
            className="text-grey text-bold border-3 rounded-md hover:text-[#1a735e] p-2"
          >
            {isExampleCollapsed ? "Expand" : "Collapse"}
          </button>
          <button
            onClick={(e) => {
              e.preventDefault();
              deleteExamplePair(example.id);
            }}
            className="text-gray-500 hover:text-gray-700 transition duration-300 p-2"
            aria-label="Delete example"
          >
            <FaTrashAlt className="w-4 h-4" />
          </button>
        </div>
      </div>

      {!collapsed && (
        <div className="flex flex-col gap-4">
          <div className="w-full">
            <label className="block text-sm font-medium text-gray-600">
              Input Text ({type === "examples" ? "Positive" : "Negative"}{" "}
              Example)
            </label>
            <div className="relative">
              <textarea
                className="w-full p-2 border border-gray-300 rounded-md focus:ring-primary focus:border-primary transition duration-300 resize-none"
                value={example?.evidence || ""}
                onChange={(e) => {
                  e.preventDefault();
                  updateExamplePair(example.id, "evidence", e.target.value);
                }}
                placeholder={
                  type === "examples"
                    ? "E.g., The product arrived on time and in perfect condition."
                    : "E.g., The delivery was late and the package was damaged."
                }
                rows={3}
              />
              <button
                onClick={() => {
                  navigator.clipboard.writeText(example?.evidence || "");
                  setCopySuccess({ ...copySuccess, evidence: true });
                  setTimeout(
                    () => setCopySuccess({ ...copySuccess, evidence: false }),
                    2000,
                  );
                }}
                className={`absolute top-2 right-2 p-1.5 rounded-md transition-all duration-200 
                  ${
                    copySuccess.evidence
                      ? "bg-green-100 text-green-600"
                      : "bg-gray-100/70 text-gray-500 hover:bg-gray-200/70 hover:text-gray-700"
                  }`}
                title={copySuccess.evidence ? "Copied!" : "Copy text"}
              >
                {copySuccess.evidence ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z"
                    />
                  </svg>
                )}
              </button>
            </div>
          </div>
          <div className="w-full">
            <label className="block text-sm font-medium text-gray-600">
              Expected Output
            </label>
            <div className="relative">
              <textarea
                className="w-full p-2 border border-gray-300 rounded-md focus:ring-primary focus:border-primary transition duration-300 resize-none"
                value={example.value}
                onChange={(e) => {
                  e.preventDefault();
                  updateExamplePair(example.id, "value", e.target.value);
                }}
                placeholder={
                  type === "examples"
                    ? "E.g., Positive review"
                    : "E.g., Positive review"
                }
                rows={3}
              />
              <button
                onClick={() => {
                  navigator.clipboard.writeText(example.value || "");
                  setCopySuccess({ ...copySuccess, value: true });
                  setTimeout(
                    () => setCopySuccess({ ...copySuccess, value: false }),
                    2000,
                  );
                }}
                className={`absolute top-2 right-2 p-1.5 rounded-md transition-all duration-200 
                  ${
                    copySuccess.value
                      ? "bg-green-100 text-green-600"
                      : "bg-gray-100/70 text-gray-500 hover:bg-gray-200/70 hover:text-gray-700"
                  }`}
                title={copySuccess.value ? "Copied!" : "Copy text"}
              >
                {copySuccess.value ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z"
                    />
                  </svg>
                )}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ExamplesSection;
