import { useContext } from "react";
import { BaseContext } from "../../../contexts/BaseContext";

const DefaultPage = () => {
  const { setActivePage, activePageToData, disabledPages } =
    useContext(BaseContext);

  return (
    <div className="max-w-4xl mx-auto p-8">
      <h2 className="text-3xl font-bold text-gray-800 mb-6 text-center">
        DeasyLabs
      </h2>
      <p className="text-lg text-gray-600 mb-12 text-center">
        Metadata enhanced retrieval and management for your RAG applications
      </p>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        {Object.entries(activePageToData).map(([page, data], index) => (
          <div
            key={index}
            className={`bg-emerald-200 rounded-lg shadow-md overflow-hidden transition-all duration-300 ${
              !disabledPages.includes(page)
                ? "hover:shadow-lg cursor-pointer"
                : "opacity-50 cursor-not-allowed"
            }`}
            title={
              !disabledPages.includes(page)
                ? "Click to navigate to this page"
                : "This feature is coming soon!"
            }
            onClick={() => !disabledPages.includes(page) && setActivePage(page)}
          >
            <div className="p-6">
              <div className="flex items-center mb-4">
                <div className="text-emerald-600 mr-3">{data.icon}</div>
                <h3 className="text-xl font-semibold text-gray-800">
                  {data.title}
                </h3>
              </div>
              <p className="text-gray-600 mb-4">{data.helpDescription}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DefaultPage;
