import { useContext, useState } from "react";
import { BaseContext } from "../../contexts/BaseContext";
import { useNavigate } from "react-router-dom";

const NavBar = () => {
  const {
    disabledPages,
    activePage,
    setActivePage,
    activePageToData,
    signOut,
  } = useContext(BaseContext);
  const [isExpanded, setIsExpanded] = useState(true);
  const navigate = useNavigate();

  const handleNavigation = (page) => {
    setActivePage(page);
    if (page === "tokenDashboard") {
      navigate("/token");
    } else {
      navigate("/");
    }
  };

  return (
    <div
      className={`relative ${
        isExpanded ? "w-56" : "w-16"
      } transition-all duration-300 ease-in-out bg-emerald-950 flex flex-col items-center py-4 px-2`}
    >
      <button
        className="absolute -right-3 top-6 bg-emerald-800 rounded-full p-1.5 text-white hover:bg-emerald-700 shadow-md"
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className={`h-4 w-4 transition-transform duration-300 ease-in-out ${
            isExpanded ? "rotate-180" : ""
          }`}
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M9 5l7 7-7 7"
          />
        </svg>
      </button>

      <div
        className="w-8 h-8 mb-8 flex items-center justify-center cursor-pointer"
        onClick={() => setActivePage("")}
      >
        <img
          src="/deasy-icon.png"
          alt="DeasyLabs"
          className="w-full h-full rounded-lg brightness-125"
        />
        {isExpanded && (
          <span className="ml-2 text-white text-xl font-bold tracking-wider">
            DeasyLabs
          </span>
        )}
      </div>

      <nav className="flex flex-col gap-4 w-full">
        {Object.entries(activePageToData).map(([page, data]) => (
          <button
            key={page}
            className={`p-2 rounded-lg flex items-center w-full ${
              disabledPages.includes(page)
                ? "text-gray-500 cursor-not-allowed"
                : "text-white hover:bg-emerald-800"
            } ${activePage === page ? "bg-emerald-800" : ""}`}
            title={
              disabledPages.includes(page)
                ? "This feature is coming soon!"
                : data.title
            }
            disabled={disabledPages.includes(page)}
            onClick={() => handleNavigation(page)}
          >
            <span
              className={`min-w-[24px] ${
                disabledPages.includes(page)
                  ? "text-emerald-800"
                  : "text-emerald-400"
              }`}
            >
              {data.icon}
            </span>
            {isExpanded && (
              <span
                className={`ml-2 text-sm font-medium tracking-wide whitespace-nowrap ${
                  disabledPages.includes(page)
                    ? "text-gray-500"
                    : "text-gray-100"
                }`}
              >
                {data.title}
              </span>
            )}
          </button>
        ))}
      </nav>

      <button
        className="mt-auto p-2 text-white hover:bg-emerald-900 rounded-lg flex items-center w-full px-4"
        title="Sign Out"
        onClick={() => signOut()}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6 text-emerald-400"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
          />
        </svg>
        {isExpanded && (
          <span className="ml-2 text-sm font-medium tracking-wide whitespace-nowrap text-gray-100">
            Sign Out
          </span>
        )}
      </button>
    </div>
  );
};

export default NavBar;
