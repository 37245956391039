import TagsTable from "./TagsTable";
import { useContext } from "react";
import { BaseContext } from "../../../../contexts/BaseContext";

const TagSelector = ({ expanded }) => {
  const { tagInEditor } = useContext(BaseContext);

  return (
    <div className={`h-full flex flex-col ${expanded ? "p-4" : ""}`}>
      <div className="border-b border-gray-200 bg-gray-50 rounded-t-lg">
        <div className="flex items-center justify-between p-4">
          <div className="flex flex-col">
            <h2 className="font-semibold text-left">Tag Selector</h2>
            {tagInEditor?.name && (
              <span className="text-sm text-gray-600 mt-1">
                Selected:{" "}
                <span className="font-medium">{tagInEditor.name}</span>
              </span>
            )}
          </div>
        </div>
      </div>
      <div className="flex-1 overflow-hidden">
        <TagsTable />
      </div>
    </div>
  );
};

export default TagSelector;
