import { createContext, useState, useEffect } from "react";
import { FaChartLine, FaDatabase, FaBullseye } from "react-icons/fa";
import { RiDashboard3Line } from "react-icons/ri";
import { AiFillExperiment } from "react-icons/ai";
import { MdConveyorBelt } from "react-icons/md";
import { IoMdPricetags } from "react-icons/io";
import { auth } from "../config/firebase";
import { config } from "../config/config";

export const BaseContext = createContext();

export const BaseProvider = ({ children }) => {
  // Page configuration
  const activePageToData = {
    tokenDashboard: {
      title: "API keys and credits",
      helpDescription: "Manage your Deasy API keys and add credits",
      icon: <FaChartLine className="w-5 h-5" />,
    },
    vectorDatabase: {
      title: "Vector Database",
      helpDescription: "Configure your Vector Database",
      icon: <FaDatabase className="w-5 h-5" />,
    },
    metadataDashboard: {
      title: "Metadata Dashboard",
      helpDescription: "View the health of your Vector Database's Metadata",
      icon: <RiDashboard3Line className="w-5 h-5" />,
    },
    autoCreateTags: {
      title: "Auto-Create Tags",
      helpDescription: "Automatically create tags based on your data",
      icon: <MdConveyorBelt className="w-5 h-5" />,
    },
    taggingStudio: {
      title: "Tagging Studio",
      helpDescription: "Test and refine your tags",
      icon: <AiFillExperiment className="w-5 h-5" />,
    },
    savedTags: {
      title: "Saved Tags",
      helpDescription: "View all your saved tags",
      icon: <IoMdPricetags className="w-5 h-5" />,
    },
    evaluateRAG: {
      title: "Evaluate RAG",
      helpDescription: "Evaluate your RAG pipeline",
      icon: <FaBullseye className="w-5 h-5" />,
    },
  };
  const disabledPages = config.features.disabledPages;
  const [vectorDBConfiguration, setVectorDBConfiguration] = useState(
    config.vectorDB.default,
  );
  const [llmEndpointConfiguration, setLlmEndpointConfiguration] = useState(
    config.llmEndpoint.default,
  );
  const [activePage, setActivePage] = useState("");
  const [metadataHealth, setMetadataHealth] = useState(null);
  const [deasyApiKey, setDeasyApiKey] = useState(config.deasy.apiKey);
  const [activeTagEditorSection, setActiveTagEditorSection] =
    useState("general");
  const [savedTags, setSavedTags] = useState(() => {
    try {
      // Try to get from localStorage if exists
      const saved = localStorage.getItem("savedTags");
      return saved ? JSON.parse(saved) : [];
    } catch {
      return [];
    }
  });
  const [tagInEditor, setTagInEditor] = useState({});
  const [isTagEditorCollapsed, setIsTagEditorCollapsed] = useState(false);
  const [availableFiles, setAvailableFiles] = useState([]);
  const [taggingStudioOutput, setTaggingStudioOutput] = useState(null);

  // Add effect to persist savedTags
  useEffect(() => {
    if (Array.isArray(savedTags)) {
      localStorage.setItem("savedTags", JSON.stringify(savedTags));
    }
  }, [savedTags]);

  useEffect(() => {
    const path = window.location.pathname;
    if (path === "/token") {
      setActivePage("tokenDashboard");
    } else if (path === "/") {
      setActivePage("");
    }
  }, []);

  // Utility functions
  const signOut = () => {
    setVectorDBConfiguration(config.vectorDB.default);
    setLlmEndpointConfiguration(config.llmEndpoint.default);
    setActivePage("");
    setMetadataHealth(null);
    setDeasyApiKey("");
    setSavedTags([]);
    setTagInEditor({});
    setAvailableFiles([]);
    setTaggingStudioOutput(null);
    auth.signOut();
  };

  const exportData = ({ type = "json" }) => {
    if (!savedTags?.length) return;

    if (type === "json") {
      const jsonData = savedTags.reduce((acc, tag) => {
        const { tag_id, username, created_at, updated_at, ...cleanedTag } = tag;
        acc[tag.name] = cleanedTag;
        return acc;
      }, {});

      const blob = new Blob([JSON.stringify(jsonData, null, 2)], {
        type: "application/json",
      });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "tags.json";
      a.click();
    }
  };

  return (
    <BaseContext.Provider
      value={{
        // Getters
        activePage,
        activePageToData,
        vectorDBConfiguration,
        llmEndpointConfiguration,
        metadataHealth,
        deasyApiKey,
        savedTags,
        tagInEditor,
        isTagEditorCollapsed,
        disabledPages,
        availableFiles,
        taggingStudioOutput,
        activeTagEditorSection,

        // Setters
        setActivePage,
        setVectorDBConfiguration,
        setLlmEndpointConfiguration,
        setMetadataHealth,
        setDeasyApiKey,
        setSavedTags,
        setTagInEditor,
        setIsTagEditorCollapsed,
        setAvailableFiles,
        setTaggingStudioOutput,
        setActiveTagEditorSection,
        // Functions
        signOut,
        exportData,
      }}
    >
      {children}
    </BaseContext.Provider>
  );
};
