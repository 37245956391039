const GeneralSection = ({
  formData,
  setFormData,
  handleInputChange,
  handleSave,
  saving,
}) => {
  const handleOutputTypeChange = (e) => {
    const newOutputType = e.target.value;
    setFormData((prev) => ({
      ...prev,
      output_type: newOutputType,
    }));
    if (newOutputType === "boolean") {
      setFormData((prev) => ({
        ...prev,
        available_values: [],
      }));
    }
  };

  return (
    <>
      <div className="space-y-2">
        <label className="block text-sm font-medium text-gray-700 text-left">
          Name
        </label>
        <input
          type="text"
          name="name"
          value={formData.name || ""}
          onChange={handleInputChange}
          placeholder="Enter a name for the tag"
          className="w-full p-2 border rounded focus:ring-2 focus:ring-emerald-500 focus:border-emerald-500 outline-none"
        />
      </div>

      <div className="space-y-2">
        <label className="block text-sm font-medium text-gray-700 text-left">
          Description
        </label>
        <textarea
          name="description"
          value={formData.description || ""}
          onChange={handleInputChange}
          placeholder="Enter a description for the tag"
          className="w-full p-2 border rounded focus:ring-2 focus:ring-emerald-500 focus:border-emerald-500 outline-none"
          rows="3"
        />
      </div>

      <div className="space-y-2">
        <label className="block text-sm font-medium text-gray-700 text-left">
          Output Type
        </label>
        <select
          name="output_type"
          value={formData.output_type || "word"}
          onChange={handleOutputTypeChange}
          className="w-full p-2 border rounded focus:ring-2 focus:ring-emerald-500 focus:border-emerald-500 outline-none"
        >
          <option value="word">Word</option>
          <option value="number">Number</option>
          <option value="date">Date</option>
        </select>
      </div>

      {formData.output_type !== "boolean" && (
        <div className="space-y-2">
          <label className="block text-sm font-medium text-gray-700 text-left">
            Available Values
          </label>
          <textarea
            name="available_values"
            value={
              Array.isArray(formData.available_values)
                ? formData.available_values.join("\n")
                : ""
            }
            onChange={handleInputChange}
            className="w-full p-2 border rounded focus:ring-2 focus:ring-emerald-500 focus:border-emerald-500 outline-none"
            rows="3"
            placeholder="Enter values, one per line"
          />
        </div>
      )}
    </>
  );
};

export default GeneralSection;
