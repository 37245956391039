import React from "react";

const LoadingState = ({
  text = "Loading...",
  size = "default",
  theme = "light",
}) => {
  const sizeClasses = {
    small: "h-[100px]",
    default: "h-[200px]",
    large: "h-[300px]",
    full: "h-full",
  };

  const spinnerSizes = {
    small: "loading-sm",
    default: "loading-md",
    large: "loading-lg",
  };

  const themeClasses = {
    light: "bg-white",
    dark: "bg-gray-800",
    transparent: "bg-transparent",
  };

  return (
    <div
      className={`flex flex-col items-center justify-center ${sizeClasses[size]} ${themeClasses[theme]}`}
    >
      {/* Animated Loading Icon */}
      <div className="relative">
        <span
          className={`loading loading-spinner ${spinnerSizes[size]} text-primary`}
        ></span>
        <div className="absolute inset-0 flex items-center justify-center">
          <div
            className={`animate-pulse w-2 h-2 rounded-full bg-primary ${
              size === "large"
                ? "scale-150"
                : size === "small"
                  ? "scale-75"
                  : "scale-100"
            }`}
          ></div>
        </div>
      </div>

      {/* Loading Text */}
      <div className="mt-4 space-y-2">
        <p
          className={`text-gray-600 ${
            size === "large"
              ? "text-lg"
              : size === "small"
                ? "text-sm"
                : "text-base"
          }`}
        >
          {text}
        </p>
        <div className="flex justify-center gap-1">
          <span
            className="w-2 h-2 rounded-full bg-primary animate-bounce"
            style={{ animationDelay: "0ms" }}
          ></span>
          <span
            className="w-2 h-2 rounded-full bg-primary animate-bounce"
            style={{ animationDelay: "100ms" }}
          ></span>
          <span
            className="w-2 h-2 rounded-full bg-primary animate-bounce"
            style={{ animationDelay: "200ms" }}
          ></span>
        </div>
      </div>
    </div>
  );
};

export default LoadingState;
